import React from 'react'
import * as ROUTES from '../../constants/routes'

import './letsmake.scss'
import { Row, Col } from 'reactstrap'

const LetsMake = () => {
	return (
		<Row className="lets-make full-width">
			<div className="bg-blue-mirrored-part-one"></div>
			<Col xs="12" className="text-center">
				<h2>LET'S MAKE SOMETHING AWESOME!</h2>
				<p>We love creating amazing experiences for our players & our partners.</p>
				<div className="mt-5">
					<a href={ROUTES.CONTACT}>
						<button className="button-brush-blue-1 lets-make-button">get in touch</button>
					</a>
				</div>
			</Col>
			<div className="bg-blue-mirrored-part-two"></div>
		</Row>
	)
}

export default LetsMake