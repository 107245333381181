import React from 'react'
import * as ROUTES from '../../constants/routes'

import './getintouch.scss'
import { Row, Col } from 'reactstrap'

const GetInTouch = () => {
	return (
		<Row className="get-in-touch full-width">
			<div className="bg-blue-mirrored-part-one"></div>
			<Col xs="12" className="text-center">
				<h2>GET IN TOUCH!</h2>
				<p>
					Find out how we can build a cohesive bridge with your team to make your vision a reality.
				</p>
				<div className="mt-5">
					<a href={ROUTES.CONTACT}>
						<button className="button-brush-blue-1 get-in-touch-button">let's create together</button>
					</a>
				</div>
			</Col>
			<div className="bg-blue-mirrored-part-two"></div>
		</Row>
	)
}

export default GetInTouch
