import React, { Component } from 'react'
import {
	Row, Col, Container
} from 'reactstrap'

import ReactGA from 'react-ga'
import './services.scss'
import * as ROUTES from '../../constants/routes'
import GetInTouch from '../GetInTouchComponent'

import elements_logo from './../../assets/images/home/elements_logo.png'

import games from './../../assets/images/services/Services-Game-Design-and-Dev.png'
import design from './../../assets/images/services/Services-App-Design-and-Dev.png'
import uiUx from './../../assets/images/services/Services-UI-_-UX.png'
import backendExpertise from './../../assets/images/services/Services-Backend-Expertise-.png'
import cloudServices from './../../assets/images/services/Services-Cloud-Services.png'
import brandedIP from './../../assets/images/services/Services-Branded-IP-v02.png'

const INITIAL_STATE = {}

class ServicesComponent extends Component {
	constructor(props) {
		super(props)

		this.state = { ...INITIAL_STATE }
	}

	componentDidMount = () => {
		ReactGA.initialize('UA-172432218-1')
		ReactGA.event({
			category: 'page',
			action: 'User has entered at service page'
		})
	}

	render() {
		return (
			<>
				<Container fluid id="service-component">
					<div className="banner-header row full-width">
						<Col xs="12" className="text-center">
							<h1>From concept to completion, and everywhere in between, we maximize game & app success</h1>
						</Col>
					</div>
					<Row className="each-partnership">
						<Col xs="6" className="text-right partnership-text-1">
							<p>We begin each partnership by laying the synchronized groundwork through:</p>
							<span>
								<li>in depth analysis of the project <span>+</span></li>
								<li>detailed plan of specs and requirements <span>+</span></li>
								<li>visuals, documentation, wireframes, UI, diagrams, and functionality models</li>
							</span>
						</Col>
						<Col xs="6" className="text-left partnership-text-2">
							<p>We then dive into the development, all the while tracking milestones, art production, coding & testing alongside our partners.</p>
						</Col>
					</Row>
					<Row className="what-we-do full-width">
						<div className="bg-blue-part-one"></div>
						<Col xs="12" className="text-center">
							<h2>WHAT WE DO</h2>
						</Col>
					</Row>
					<Row className="what-we-do with-bg full-width">
						<Row className="what-we-do-p namazu-bd">
						<Col xs="6" className="text-center services-content">
							<div className="p-2 wrapper">
								<img src={games} className="img-fluid" alt="game design" />
								<p className="text">
								We create engaging content & gameplay to bring your concept to life. We develop in Unity for crossplatform compatibility. We’re experts in mobile and console gaming, VR, AR and casino platforms.
							</p>
							</div>
						</Col>
						<Col xs="6" className="text-center services-content">
							<div className="p-2 wrapper">
								<img src={design} className="img-fluid" alt="app design" />
								<p className="text">
								We develop apps natively from the ground up, or with crossplatform frameworks. Partner with us to create a fun & responsive application experience uniquely built with users’ needs at center stage.
							</p>
							</div>
						</Col>
						<Col xs="6" className="text-center services-content">
							<div className="p-2 wrapper">
								<img src={uiUx} className="img-fluid" alt="topic" />
								<p className="text">
								We design to provide an intuitive user experience and achieve business goals. We are experienced in creating engaging experiences for games, apps, and casino technology across a variety of platforms: console, mobile, tablet, desktop, and proprietary hardware.
							</p>
							</div>
						</Col>
						<Col xs="6" className="text-center services-content">
							<div className="p-2 wrapper">
								<img src={backendExpertise} className="img-fluid" alt="topic" />
								<p className="text">
								We are experts in server-side applications. We can build custom solutions, and we have developed our own backend tech that will save clients time and money. Let us leverage Elements for your project.
							</p>
							</div>
						</Col>
						<Col xs="6" className="text-center services-content">
							<div className="p-2 wrapper">
								<img src={cloudServices} className="img-fluid" alt="topic" />
								<p className="text">
								We offer scalable, on-demand, cloud-based services to keep pace with user demands for apps relying on web services or digital content distribution. We integrate mobile apps with both existing and custom built RESTful APIs. Our server applications are developed with proven Java technology and run in Amazon Web Services, Google Cloud, or private hosting solutions.
							</p>
							</div>
						</Col>
						<Col xs="6" className="text-center services-content">
							<div className="p-2 wrapper">
								<img src={brandedIP} className="img-fluid" alt="topic" />
								<p className="text">
								We’ll follow stylistic guidelines to create engaging experiences featuring your branded content. Our team’s art, design, and programming skills will take any popular IP to the next level through fun games and apps.
								</p>
							</div>
						</Col>
						</Row>
						<div className="bg-blue-part-two"></div>
					</Row>
						<Row className="what-we-do">
						<Col xs="12" className="text-center">
							<div className="p-2 elements-text">
								<p className="texts">
									Namazu works with <strong>Elements:</strong> a massively scalable, ready-to-plug-in technology that
									guarantees a smooth launch & optimal performance for multiplayer games and applications, without tying you
									down to a specific architecture or additional software purchases.
							</p>
								<p className="m-0 texts"><strong>It also offers:</strong></p>
								<ul>
									<li className="list">Full integration with Facebook, Adjust, Firebase, Amazon’s GameOn, and other solutions</li>
									<li className="list">Host multiple applications and share users across the board, and get notifications out seamlessly in one easy step</li>
									<li className="list">Provide head-to-head tournaments, matching with friends, and player-vs-player real-world prize tournaments</li>
									<li className="list">and more!</li>
								</ul>
							</div>
							<div className="mt-3 mb-3">
								<img src={elements_logo} width="250px" alt="elements" className="img-fluid rounded" />
							</div>
							<div>
								<a href="https://www.namazuelements.com">
									<button className="button-brush-blue-1">learn more</button>
								</a>
							</div>
						</Col>
					</Row>
					<GetInTouch />
				</Container>
			</>
		)
	}
}

export default ServicesComponent