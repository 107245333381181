import React, { Component } from 'react'
import {
	Container, Row, Col
} from 'reactstrap'
import './navbar.scss'
import HamburgerMenu from './../HamburgerComponent'
import { isMobile } from 'react-device-detect'

import * as ROUTES from '../../constants/routes'

import namazu_logo from './../../assets/images/logos/logo-black.png'

const INITIAL_STATE = {
	logoWidth: 270,
	open: [false, true, false, true]
}
class NavbarComponent extends Component {
	constructor(props) {
		super(props)

		this.state = { ...INITIAL_STATE }
		this.handleScroll = this.handleScroll.bind(this)
	}

	componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        let scrollTop = window.scrollY,
                minWidth = 150,
                logoWidth = Math.max(minWidth, 250 - scrollTop);
        this.setState({
                logoWidth: logoWidth
            }
		);
	}

	handleClick(id) {
		let { open } = this.state;
		console.log(open)
    	this.setState({
			open: [...open.slice(0, id), !open[id], ...open.slice(id + 1)]
		});
	}
	
	render() {
		const { pathname } = window.location;
		// const Menu = () => {
		// 	if (isMobile) {
		// 		return (
		// 			<>
		// 			<Container fluid id="navbar-namazu">
		// 			<div className={this.state.open[0] === true ? `opened-menu mobile` : 'mobile'}>
		// 				<Col xs="12" sm="12" md="12" className="menu">
		// 					<div className="navbar-brand logo" style={{width: this.state.logoWidth}}>
		// 						<a href={ROUTES.HOME}>
		// 							<img src={namazu_logo} 
		// 							className="img-fluid" alt="Namazu Studios" />
		// 						</a>
		// 					</div>
		// 					<HamburgerMenu
		// 						className='hamburger'
		// 						isOpen={this.state.open[0]}
		// 						menuClicked={this.handleClick.bind(this, 0)}
		// 						width={36}
		// 						height={30}
		// 						strokeWidth={2}
		// 						rotate={0}
		// 						color='black'
		// 						borderRadius={5}
		// 						animationDuration={0.3}
		// 					/>
		// 					{this.state.open[0] === true &&
		// 						<div className="item-menu">
		// 							<ul>
		// 								<a href={ROUTES.HOME}>
		// 									<li>home</li>
		// 								</a>
		// 								<a href={ROUTES.ABOUTUS}>
		// 									<li>about us</li>
		// 								</a>
		// 								<a href={ROUTES.SERVICES}>
		// 									<li>services</li>
		// 								</a>
		// 								<a href={ROUTES.CLIENTS}>
		// 									<li>clients</li>
		// 								</a>
		// 								<a href={ROUTES.PORTFOLIO}>
		// 									<li>portfolio</li>
		// 								</a>
		// 								<a href={ROUTES.CONTACT}>
		// 									<li>contact</li>
		// 								</a>									
		// 							</ul>
		// 						</div>
		// 					}
		// 				</Col>
		// 			</div>
		// 			</Container>
		// 			</>
		// 		)
		// 	}
			return (
				<>
 			<Container fluid id="navbar-namazu">
			 <div className={this.state.open[0] === true ? `opened-menu mobile` : 'mobile'}>
						<Col xs="12" sm="12" md="12" className="menu">
							<div className="navbar-brand logo" style={{width: this.state.logoWidth}}>
								<a href={ROUTES.HOME}>
									<img src={namazu_logo} 
									className="img-fluid" alt="Namazu Studios" />
								</a>
							</div>
							<HamburgerMenu
								className='hamburger'
								isOpen={this.state.open[0]}
								menuClicked={this.handleClick.bind(this, 0)}
								width={36}
								height={30}
								strokeWidth={2}
								rotate={0}
								color='black'
								borderRadius={5}
								animationDuration={0.3}
							/>
							{this.state.open[0] === true &&
								<div className="item-menu">
									<ul>
										<a href={ROUTES.HOME}>
											<li>home</li>
										</a>
										<a href={ROUTES.ABOUTUS}>
											<li>about us</li>
										</a>
										<a href={ROUTES.SERVICES}>
											<li>services</li>
										</a>
										<a href={ROUTES.CLIENTS}>
											<li>clients</li>
										</a>
										<a href={ROUTES.PORTFOLIO}>
											<li>portfolio</li>
										</a>
										<a href={ROUTES.CONTACT}>
											<li>contact</li>
										</a>									
									</ul>
								</div>
							}
						</Col>
					</div>
				</Container>
				<Container fluid id="navbar-namazu">
				<div>
					<Col xs="12" sm="12" md="12" className="menu desktop">
						<div className="menu-namazu">
							<ul className="navbar-nav">
								<a href={ROUTES.HOME}>
									<li className={`border-menu button-namazu${(pathname === '/' ? "-active font-weight-bold " : " ")} nav-item`}>
										Home
								</li>
								</a>
								<a href={ROUTES.ABOUTUS}>
									<li className={`border-menu button-namazu${(pathname === '/about-us' ? "-active font-weight-bold " : " ")} nav-item`}>
										About Us
								</li>
								</a>
								<a href={ROUTES.SERVICES}>
									<li className={`border-menu button-namazu${(pathname === '/services' ? "-active font-weight-bold " : " ")} nav-item`}>
										Services
								</li>
								</a>
							</ul>
							<div className="navbar-brand logo" style={{width: this.state.logoWidth}}>
								<a href={ROUTES.HOME}>
									<img src={namazu_logo} 
									className="img-fluid" alt="Namazu Studios" />
								</a>
							</div>
							<div>
								<ul className="navbar-nav">
									<a href={ROUTES.CLIENTS}>
										<li className={`border-menu button-namazu${(pathname === '/clients' ? "-active font-weight-bold " : " ")} nav-item`}>
											Clients
								</li>
									</a>
									<a href={ROUTES.PORTFOLIO}>
										<li className={`border-menu button-namazu${(pathname === '/portfolio' ? "-active font-weight-bold " : " ")} nav-item`}>
											Portfolio
								</li>
									</a>
									<a href={ROUTES.CONTACT}>
										<li className={`border-menu button-namazu${(pathname === '/contact' ? "-active " : " ")} nav-item`}>
											Contact
								</li>
									</a>
								</ul>
							</div>
						</div>
					</Col>
				</div>
				</Container>
				</>
			);
		// };
        // return Menu();
    }
}

export default NavbarComponent