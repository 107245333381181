import React, { Component } from 'react'
import {
	Row, Col, Container
} from 'reactstrap'

import ReactGA from 'react-ga'
import './about.scss'
import * as ROUTES from '../../constants/routes'
import JoinUs from '../JoinusComponent'

import { isMobile } from 'react-device-detect'

import elements_logo from './../../assets/images/home/elements_logo.png'
import pc from './../../assets/images/about-us/pc.png'
import mobile from './../../assets/images/about-us/mobile.png'
import _console from './../../assets/images/about-us/console.png'
import socialMedia from './../../assets/images/about-us/social media.png'
import uiUx from './../../assets/images/about-us/uiux-expertise.png'
import gameTechnologies from './../../assets/images/about-us/game technologies.png'
import casino from './../../assets/images/about-us/casino.png'
import _3d from './../../assets/images/about-us/3d.png'
import expertiseImage from './../../assets/images/about-us/AboutUs-imgExpertise.png'
import backend from './../../assets/images/about-us/backend.png' 
import bgCatfish from './../../assets/images/about-us/AboutUs-imagemLateral.png'

import teamMember1 from './../../assets/images/about-us/our-team/keith.jpg'
import teamMember2 from './../../assets/images/about-us/our-team/Patrick.jpg'
import teamMember3 from './../../assets/images/about-us/our-team/Samuel.jpg'

import businessDeck from './../../assets/files/Namazu2020-BusinessDeck.pdf'

const INITIAL_STATE = {}

class AboutComponent extends Component {
	constructor(props) {
		super(props)

		this.state = { ...INITIAL_STATE }
	}

	componentDidMount = () => {
		ReactGA.initialize('UA-172432218-1')
		ReactGA.event({
			category: 'page',
			action: 'User has entered at about us page'
		})
	}

	render() {
		return (
			<>
				<Container fluid id="about-component">
					
					<div className="banner-header row full-width">
						<Col xs="12" className="text-center">
							<h1>Experts in External Development</h1>
						</Col>
					</div>
					<div className="shakes-things-up row full-width">
						<Row className="namazu-w">
						<Col xs="6" className='text-right catfish-wp catfish'>
							<img src={bgCatfish} />
						</Col>
						<Col xs="6" className='text-right catfish align-t'>
							<h2>NAMAZU SHAKES THINGS UP</h2>
							<div className="text-catfish">
								<p>Namazu Studios was founded in 2008 with a vision to deliver unparalleled gaming experiences with a high level of quality and polish. With years of IT and software development experience under our belt, we deliver projects efficiently, on budget and on time. We provide our clients expert advice and accurate estimates. We have published games and apps to the iTunes App Store, Android Market, PlayStation Network, Steam, and more. Our team is comprised of motivated engineers, artists, and designers with vision and determination to innovate in our field.Our expertise in server-side technology sets us apart, including our proprietary server solution (Elements) which we can leverage to deliver projects at a lower cost.</p>
							</div>
							<div className="business-deck-button text-left">
								<a href={businessDeck} download={businessDeck}>
									<button className="button-brush-3">download our deck</button>
								</a>
							</div>
						</Col>
						</Row>
					</div>
					<Row className="expertise full-width">
						<div className="bg-grey-part-one"></div>
						
						<Row className="namazu-g">
						<Col className="text-center" xs="12" sm="12"  lg="4" xl="4">
							<img className="side-image" src={expertiseImage} alt="Expertise" />
						</Col>
						
						<Col className="text-center bg-grey" xs="12" sm="12" md="12" lg="8" xl="8">
							<Row></Row>
							<h2>EXPERTISE + PLATFORMS</h2>
							<div className="expertise-row">
								<div className="expertise-icon">
									<img src={mobile} width="70" alt="Icon" />
									<p>MOBILE</p>
									<small>iPhone and Android</small>
								</div>
								<div className="expertise-icon">
									<img src={_console} width="70" alt="Icon" />
									<p>CONSOLE</p>
									<small>Playstation 4, PS Vita, XBox One</small>
								</div>
								<div className="expertise-icon">
									<img src={pc} width="70" alt="Icon" />
									<p>PC</p>
									<small>Mac and Windows</small>
								</div>
								<div className="expertise-icon">
									<img src={gameTechnologies} width="70" alt="Icon" />
									<p>GAME TECH</p>
									<small>Engine Unity + VR, AR and casino platforms</small>
								</div>
							</div>
							<div className="expertise-row">
								<div className="expertise-icon">
									<img src={_3d} width="70" alt="Icon" />
									<p>Art asset</p>
									<small>2D and 3D</small>
								</div>
								<div className="expertise-icon">
									<img src={uiUx} width="70" alt="Icon" />
									<p>ux ui</p>
									<small>From design to completion</small>
								</div>
								<div className="expertise-icon">
									<img src={casino} width="70" alt="Icon" />
									<p>CASINO</p>
									<small>All digital aspects</small>
								</div>
								<div className="expertise-icon">
									<img src={socialMedia} width="70" alt="Icon" />
									<p>SOCIAL MEDIA</p>
									<small>Apps with integration</small>
								</div>
								<div className="expertise-icon">
									<img src={backend} width="70" alt="Icon" />
									<p>BACKEND</p>
								</div>
							</div>
						</Col>
						{/* <Col className="text-center bg-grey" xs="12" sm="12" md="8" lg="8" xl="1"></Col> */}
						<Col xs="12" className="bg-grey padding-n text-center mt-4 pl-5 pr-5">
							<p className="t-1 text-about-us">
							Namazu works with <strong>Elements:</strong> a massively scalable, ready-to-plug-in technology that guarantees a smooth launch & optimal performance for multiplayer games and applications
							</p>

							<p className="m-0 list-title t-1">Some features:</p>
							<ul>
								<li className="list">Full integration with Facebook, Adjust, Firebase, Amazon’s GameOn, and other solutions</li>
								<li className="list">Host multiple applications and share users across the board, and get notifications out seamlessly in one easy step</li>
								<li className="list">Provide head-to-head tournaments, matching with friends, and player-vs-player real-world prize tournaments</li>
								<li className="list">and more!</li>
							</ul>

							<img src={elements_logo} width="250px" alt="elements" className="button-elements img-fluid rounded" />

							<div className="mt-5">
								<a href="https://www.namazuelements.com/">
									<button className="button-brush-blue-1">learn more about elements</button>
								</a>
							</div>
						</Col>
						</Row>
						<div className="bg-grey-part-two"></div>
					</Row>
					<Row className="our-amazing-team">
						<Col xs="12" className="text-center">
							<h2>OUR AMAZING TEAM</h2>
							<p>We are experts in turning your app or game dream into reality.</p>
							<div className="team-row spacing">
								<div className="team-column">
									<div className="team-icon">
										<img src={teamMember1} alt="Client Icon" />
										<p>Keith Hudnall</p>
										<small>CTO</small>
									</div>
								</div>
								<div className="team-column">
									<div className="team-icon">
										<img src={teamMember2} alt="Client Icon" />
										<p>Patrick Twohig</p>
										<small>CEO</small>
									</div>
								</div>
								<div className="team-column">
									<div className="team-icon">
										<img src={teamMember3} alt="Client Icon" />
										<p>Samuel Kahn</p>
										<small>COO</small>
									</div>
								</div>
							</div>
						</Col>
					</Row>
					<JoinUs />
				</Container>
			</>
		)
	}
}

export default AboutComponent