import React from 'react'
import {
	Row, Col, Container
} from 'reactstrap'
import './footer.scss'

import namazu_logo from './../../assets/images/logos/logo-black.png'
import facebook from './../../assets/images/home/FB.png'
import twitter from './../../assets/images/home/TWITTER.png'
import linkedin from './../../assets/images/home/LINKEDIN.png'
import * as ROUTES from './../../constants/routes'
import { isMobile } from 'react-device-detect'

const FooterComponent = () => {
	const { pathname } = window.location
	return (
		<>
			<Container fluid id="footer">
				<Row>
					<Col xs="12" sm="12" md="9" className="menu-itens text-left left-side mb-5">
						<div className="logo">
							<img src={namazu_logo} alt="Namazu Studios" className="img-fluid" width="200px" />
						</div>
						
						{!isMobile &&
							<div className="menu-options">
							<a href={ROUTES.HOME} className={`button-namazu${(pathname === '/' ? "-active font-weight-bold " : " ")} nav-item`}>
								Home <span>|</span>
							</a>
							<a href={ROUTES.ABOUTUS} className={`button-namazu${(pathname === '/' ? "-active font-weight-bold " : " ")} nav-item`}>
								About Us <span>|</span>
							</a>
							<a href={ROUTES.SERVICES} className={`button-namazu${(pathname === '/' ? "-active font-weight-bold " : " ")} nav-item`}>
								Services <span>|</span>
							</a>
							<a href={ROUTES.CLIENTS} className={`button-namazu${(pathname === '/' ? "-active font-weight-bold " : " ")} nav-item`}>
								Clients <span>|</span> 
							</a>
							<a href={ROUTES.PORTFOLIO} className={`button-namazu${(pathname === '/' ? "-active font-weight-bold " : " ")} nav-item`}>
								Portfolio <span>|</span> 
							</a>
							<a href={ROUTES.CONTACT} className={`button-namazu${(pathname === '/' ? "-active font-weight-bold " : " ")} nav-item`}>
								Contact
							</a>
							</div>
						}
						
					</Col>
					<Col xs="12" sm="12" md="3" className="footer-infos text-right right-side mb-5">
					<div className="social-media">
						<div className="icon">
								<a href="https://www.facebook.com/namazustudios/">
									<img src={facebook} className="img-fluid mx-auto" alt="icon social media" />
								</a>
							</div>
							<div className="icon">
								<a href="https://twitter.com/namazustudios">
									<img src={twitter} className="img-fluid mx-auto" alt="icon social media" />
								</a>
							</div>
							<div className="icon">
								<a href="https://www.linkedin.com/company/namazu-studios-llc/">
									<img src={linkedin} className="img-fluid mx-auto" alt="icon social media" />
								</a>
							</div>
					</div>
						<div className="info-namazu">
							<div>
								<span className="text-uppercase">(619) 862-2890</span>
							</div>
							<div>
								<span className="text-uppercase">info@namazustudios.com</span>
							</div>
							<div className="mt-3">
								<span className="text-uppercase">2815 camino del rio south, ste. #155</span>
							</div>
							<div>
								<span className="text-uppercase">san diego, ca 92108</span>
							</div>
							<div className="mt-3">
								<span className="text-uppercase namazu-studios">© 2018 namazu studios</span>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default FooterComponent