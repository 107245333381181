import React from 'react'
import * as ROUTES from '../../constants/routes'

import './joinus.scss'
import { Row, Col } from 'reactstrap'

const JoinUs = () => {
	return (
		<Row className="join-us full-width">
			<div className="bg-blue-mirrored-part-one"></div>
			<Col xs="12" className="text-center">
				<h2>JOIN US!</h2>
				<p>Wanna be part of this awesome team?</p>
				<p>Send us your cv!</p>
				<div className="mt-5">
					<a href={ROUTES.CONTACT}>
						<button className="button-brush-blue-1 join-us-button">get in touch</button>
					</a>
				</div>
			</Col>
			<div className="bg-blue-mirrored-part-two"></div>
		</Row>
	)
}

export default JoinUs
