import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import HomePage from '../../pages/Home'
import AboutPage from '../../pages/About'
import ServicesPage from '../../pages/Services'
import NotFound from './../NotFound'

import * as ROUTES from '../../constants/routes'

import './app.scss'
import FooterComponent from '../Footer'
import NavbarComponent from '../NavbarComponent'
import ClientsPage from '../../pages/Clients'
import PortofolioPage from '../../pages/Portfolio'
import ContactPage from '../../pages/Contact'

const App = () => {
	return (
		<Router>
			<NavbarComponent />
			<div id="main">
				<Switch>
					<Route exact path={ROUTES.HOME} render={(props) => <HomePage {...props} />} />
					<Route exact path={ROUTES.ABOUTUS} render={(props) => <AboutPage {...props} />} />
					<Route exact path={ROUTES.SERVICES} render={(props) => <ServicesPage {...props} />} />
					<Route exact path={ROUTES.CLIENTS} render={(props) => <ClientsPage {...props} />} />
					<Route exact path={ROUTES.PORTFOLIO} render={(props) => <PortofolioPage {...props} />} />
					<Route exact path={ROUTES.CONTACT} render={(props) => <ContactPage {...props} />} />
					<Route exact path={ROUTES.NOTFOUND} render={(props) => <NotFound {...props} />} />
				</Switch>
			</div>
			<FooterComponent />
		</Router>
	)
}

export default App
