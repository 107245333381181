import React, { Component } from 'react';
import {
	Container, Row, Col
} from 'reactstrap'

import ReactGA from 'react-ga'

const NotFound = () => (
	<>
		<MaintenceBase />
	</>
);


class MaintenceBase extends Component {
	componentDidMount = () => {
		ReactGA.initialize('UA-154915059-2')
		ReactGA.event({
			category: 'component',
			action: 'User has entered at component not found'
		});
		// ReactGA.event({
		// 	category: 'Maintence',
		// 	action: 'User has entered at maintence'
		// });
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col xs="12" className="text-center">
						{/* <h1>Querido(a) Flower!</h1>
						<h3>Estamos aplicando uma manutenção no sistema!</h3>
						<p>Iremos voltar em breve!</p>
						<small>Para saber de atualizações: <a href="https://twitter.com/veiguiz" target="_blank" rel="noopener noreferrer">@veiguiz</a></small> */}
						<h1>Erro 404 :(</h1>
						<p>Não foi possível localizar esta página.</p>
					</Col>
				</Row>
			</Container>
		);
	}
}


export default NotFound;
