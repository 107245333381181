import React, { Component } from 'react'
import {
	Row, Col, Container
} from 'reactstrap'

import ReactGA from 'react-ga'
import './clients.scss'
import GetInTouch from '../GetInTouchComponent'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import client1 from './../../assets/images/home/our-clients/bush league.png'
import client2 from './../../assets/images/home/our-clients/konami.png'
import client3 from './../../assets/images/home/our-clients/AGS.png'
import client4 from './../../assets/images/home/our-clients/verison-logo.png'
import client5 from './../../assets/images/home/our-clients/ikin.png'
import client6 from './../../assets/images/home/our-clients/Bally-SHFL.png'
import client7 from './../../assets/images/home/our-clients/mystic.png'
import client8 from './../../assets/images/home/our-clients/Scientific Games.png'
import client9 from './../../assets/images/home/our-clients/kidlandia.png'

import WhatTheySay from '../WhatTheySayComponent'

const INITIAL_STATE = {
	majorClients: {
		settings: {
			dots: true,
			fade: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					dots: true,
					fade: true,
					infinite: true,
					speed: 500,
					slidesToShow: 1,
					slidesToScroll: 1,
				  }
				},
			  ]
		}
	},
	whatTheySay: {
		settings: {
			dots: true,
			fade: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1
		}
	}
}

class ClientsComponent extends Component {
	constructor(props) {
		super(props)

		this.state = { ...INITIAL_STATE }
	}

	componentDidMount = () => {
		ReactGA.initialize('UA-172432218-1')
		ReactGA.event({
			category: 'page',
			action: 'User has entered at clients page'
		})
	}

	render() {
		const { majorClients, whatTheySay } = this.state
		return (
			<>
				<Container fluid id="clients-component">
					<div className="banner-header row full-width">
						<h1>we are lucky to work with amazing clients!</h1>
					</div>
					<Row className="our-clients">
						<Col xs="12" className="text-center">
							<h2>OUR CLIENTS</h2>
							<div className="client-row p-4">
								<div className="client-column">
										<div className="client-icon">
											<img src={client1} alt="Client Icon" />
										</div>
								</div>
								<div className="client-column">
										<div className="client-icon">
											<img src={client2} alt="Client Icon" />
										</div>
								</div>
								<div className="client-column">
										<div className="client-icon">
											<img src={client3} alt="Client Icon" />
										</div>
								</div>
								<div className="client-column">
										<div className="client-icon">
											<img src={client4} alt="Client Icon" />
										</div>
								</div>
								<div className="client-column">
										<div className="client-icon">
											<img src={client5} alt="Client Icon" />
										</div>
								</div>
							</div>
							<div className="client-row p-4">
								<div className="client-column">
										<div className="client-icon">
											<img src={client6} alt="Client Icon" />
										</div>
								</div>
								<div className="client-column">
										<div className="client-icon">
											<img src={client7} alt="Client Icon" />
										</div>
								</div>
								<div className="client-column">
										<div className="client-icon">
											<img src={client8} alt="Client Icon" />
										</div>
								</div>
								<div className="client-column">
										<div className="client-icon">
											<img src={client9} alt="Client Icon" />
										</div>
								</div>
							</div>
						</Col>
					</Row>
					<WhatTheySay whatTheySay={whatTheySay} />
					<GetInTouch />
				</Container>
			</>
		)
	}
}

export default ClientsComponent