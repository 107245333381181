import React, { Component, useState } from 'react'
import axios from 'axios'
import Modal from '../Modal/Modal'

import {
	Row,
	Col,
	Container,
	Button,
	Form,
	FormGroup,
	Input,
	Alert
} from 'reactstrap'

import LoadingSimple from './../Loading/simple'
import contactUsImage from './../../assets/images/contact-us/contact-us.png'
import facebook from './../../assets/images/home/FB.png'
import twitter from './../../assets/images/home/TWITTER.png'
import linkedin from './../../assets/images/home/LINKEDIN.png'

import businessDeck from './../../assets/files/Namazu2020-BusinessDeck.pdf'

import ReactGA from 'react-ga'
import './contact.scss'

const INITIAL_STATE = {
	alert: {
		status: false,
		error: false,
		message: '',
		color: ''
	},
	input: {
		name: '',
		company: '',
		phone: '',
		email: '',
		topic: '',
		message: ''
	},
	options: [
		{
			value: 'Choose Topic',
		},
		{
			value: 'Prospective Partner',
			id: 1
		},
		{
			value: 'Press',
			id: 2
		},
		{
			value: 'Jobs',
			id: 3
		}		
	],
	sending: false,
	showModal: false,
	errorSendingMessage: false
}

class ContactComponent extends Component {
	constructor(props) {
		super(props)

		this.state = { ...INITIAL_STATE }
		this.sendContactForm = this.sendContactForm.bind(this)
		this.formRef = null
	}

	componentDidMount = () => {
		ReactGA.initialize('UA-172432218-1')
		ReactGA.event({
			category: 'page',
			action: 'User has entered at contact page'
		})
	}
	
	closeModal = () => {
		this.setState({showModal: false})
	}

	closeErrorModal = () => {
		this.setState({errorSendingMessage: false})
	}

	onChange = e => {
		this.setState({
			input: {
				...this.state.input,
				[e.target.name]: e.target.value
			}
		})
		// console.log(e.targe.value)
	}

	sendContactForm = e => {
		const emailFormSubscriberUrl = 'https://ufztsfgall.execute-api.us-east-1.amazonaws.com/api/mail'

		e.preventDefault()

		this.setState({
			alert: {
				status: false,
				error: false,
				message: '',
				color: ''
			},
			input: {
				name: '',
				company: '',
				phone: '',
				email: '',
				topic: '',
				message: ''
			},
			sending: true
		})

		const data = {
			"topic": this.state.input.topic,
			"name": this.state.input.name,
			"company": this.state.input.company,
			"phone": this.state.input.phone,
			"email": this.state.input.email,
			"message": this.state.input.message
		}

		axios.create({
			headers: {
				'Content-Type': 'application/json'
			},
		}).post(`${emailFormSubscriberUrl}/`, data)
			.then(response => {
				console.log("xuxexo")
				this.setState({ sending: false });
				this.setState({ showModal: true });

				return response
			})
			.catch(err => {
				console.log("ERRO?")
				this.setState({ sending: false });
				this.setState({errorSendingMessage: true });
				throw err
			})
	}

	render() {
		const { alert, input, sending } = this.state
		return (
			<>

				<Container fluid id="contact-component">
					{
						alert.status &&
						<Row style={{ paddingTop: "30px" }}>
							<Col xs="12" className="text-center">
								<Alert color={alert.color}>{alert.message}</Alert>
							</Col>
						</Row>
					}
					<Row className="banner-header full-width">
						<div className="bg-grey-part-one"></div>
						<Col xs="12" className="text-left">
							<h1>CONTACT US TODAY</h1>
						</Col>
					</Row>
					<Row className="contact-p full-width">
						<Col xs="12" md="7" className="text-center namazu-g">
							<Form onSubmit={this.sendContactForm}>
								<FormGroup>
									<Input
										autoComplete="off"
										type="text"
										name="name"
										id="name"
										className="form-control"
										placeholder="Your name"
										value={input.name}
										required
										onChange={this.onChange}
									/>
								</FormGroup>
								<FormGroup>
									<Input
										autoComplete="off"
										type="text"
										name="company"
										id="company"
										className="form-control"
										placeholder="Company"
										value={input.company}
										required
										onChange={this.onChange}
									/>
								</FormGroup>
								<FormGroup>
									<Input
										autoComplete="off"
										type="tel"
										pattern="[0-9\-]+"
										name="phone"
										id="phone"
										className="form-control"
										placeholder="Phone"
										value={input.phone}
										required
										onChange={this.onChange}
									/>
								</FormGroup>
								<FormGroup>
									<Input
										autoComplete="off"
										type="email"
										name="email"
										id="email"
										className="form-control"
										placeholder="Email Address"
										value={input.email}
										required
										onChange={this.onChange}
									/>
								</FormGroup>
								<FormGroup>
									<Input
										autoComplete="off"
										type="select"
										name="topic"
										id="topic"
										className="form-control"
										placeholder="Choose Topic"
										value={input.topic}
										required
										onChange={this.onChange}
									>

										{this.state.options.map((option) => (
										<option value={option.id}>{option.value}</option>
										))}
									</Input>
								</FormGroup>
								<FormGroup>
									<Input
										autoComplete="off"
										type="textarea"
										name="message"
										id="message"
										className="form-control"
										placeholder="What would you like to chat about?"
										value={input.message}
										required
										onChange={this.onChange}
									/>
									
								</FormGroup>
								{
									sending &&
									<div className="text-center">
										<LoadingSimple />
									</div>
								}
								{
									!sending &&
									<div className="text-center">
										{
											this.state.showModal === true &&
											<Modal
											onCloseRequest={() => this.closeModal()}
											>
												<h2>Thank You!</h2>
												<p>Your message has been sent and we'll get in touch as soon as possible.</p>
											</Modal>
										}

										{
											this.state.errorSendingMessage === true &&
											<Modal
												onCloseRequest={() => this.closeErrorModal()}
											>
												<h2>Oops!</h2>
												<p>There was a problem sending your message. Please try again.</p>
											</Modal>
										}
											

										
										<Button className="button-brush-blue-1">Submit</Button>
									</div>

								}
							</Form>
							<div className="text-center grey-bottom business-deck-button">
								<a href={businessDeck} download={businessDeck}>
									<button className="button-brush-3">download our deck</button>
								</a>
							</div>
						</Col>
						<Col xs="12" md="5" className="base-press-kit namazu-g">
							<p className="info">+ (619) 862-2890</p>
							<p className="info mt-2 mb-0 p-0">info@namazustudios.com</p>
							<p className="info m-0 p-0">press@namazustudios.com</p>
							<p className="info m-0 p-0">jobs@namazustudios.com</p>
							<p className="info mt-4 mb-0 p-0">2815 Camino Del Rio South Suite #155</p>
							<p className="info mb-0 p-0">San Diego, CA 92108</p>

							
							<img src={contactUsImage} className="img-fluid mx-auto contact-us" alt="placeholder contact" />
						</Col>
					</Row>
					
					<Row className="full-width">
						<Col xs="12" md="5" className="text-center base-press-kit">
						</Col>
						<div className="bg-grey-part-two"></div>
					</Row>
				</Container>
			</>
		)
	}
}

export default ContactComponent