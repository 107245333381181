import React, { useRef, Component } from 'react'

import {
	Row, Col, Container, Button
} from 'reactstrap'

import Slider from 'react-slick'
import ModalVideo from 'react-modal-video'

import ReactGA from 'react-ga'
import './portfolio.scss'
import './../../../node_modules/react-modal-video/scss/modal-video.scss'

import LetsMake from '../LetsMakeComponent'

import featured1 from './../../assets/images/portfolio/01-Drop Squad.png'
import featured2 from './../../assets/images/portfolio/02-Stax.png'
import featured3 from './../../assets/images/portfolio/03-Nebulous.png'
import featured4 from './../../assets/images/portfolio/04-DDR.png'

import vitaminZ from './../../assets/images/portfolio/Vitamin Z/VITAMIN_Z0.jpg'
import vitaminZ2 from './../../assets/images/portfolio/Vitamin Z/VITAMIN_Z2.jpg'
import vitaminZ3 from './../../assets/images/portfolio/Vitamin Z/VITAMIN_Z3.jpg'
import vitaminZ4 from './../../assets/images/portfolio/Vitamin Z/VITAMIN_Z4.jpg'
import vitaminZ5 from './../../assets/images/portfolio/Vitamin Z/VITAMIN_Z5.jpg'

import pivot from './../../assets/images/portfolio/pivot 2 win/pivot2winit-1.jpg'

import pivot1 from './../../assets/images/portfolio/pivot 2 win/pivot2winit-1.jpg'
import pivot2 from './../../assets/images/portfolio/pivot 2 win/pivot2winit-2.jpg'
import pivot3 from './../../assets/images/portfolio/pivot 2 win/pivot2winit-3.jpg'

import bonusSpin from './../../assets/images/portfolio/Bonus Spin/BONUS_SPIN1_LOGO.jpg'
import bonusSpin2 from './../../assets/images/portfolio/Bonus Spin/BONUS_SPIN2.jpg'
import bonusSpin3 from './../../assets/images/portfolio/Bonus Spin/BONUS_SPIN3_COMBO.jpg'

import fusion from './../../assets/images/portfolio/Fusion Auto Roulette/AUTO_FUSION_ROULETTE1.jpg'
import fusion2 from './../../assets/images/portfolio/Fusion Auto Roulette/AUTO_FUSION_ROULETTE2_COMBO_2.jpg'

import iDuel from './../../assets/images/portfolio/iDuel Online/IDUEL1.jpg'
import iDuel2 from './../../assets/images/portfolio/iDuel Online/IDUEL2.jpg'
import iDuel3 from './../../assets/images/portfolio/iDuel Online/IDUEL3.jpg'

import indycar from './../../assets/images/portfolio/Indycar/INDYCAR1.jpg'
import indycar2 from './../../assets/images/portfolio/Indycar/INDYCAR2.jpg'
import indycar3 from './../../assets/images/portfolio/Indycar/INDYCAR3.jpg'

import nebulous from './../../assets/images/portfolio/nebulous/NEBULOUS1.jpg'
import nebulous2 from './../../assets/images/portfolio/nebulous/NEBULOUS2.jpg'
import nebulous3 from './../../assets/images/portfolio/nebulous/NEBULOUS3.jpg'
import nebulous4 from './../../assets/images/portfolio/nebulous/NEBULOUS4.jpg'
import nebulous5 from './../../assets/images/portfolio/nebulous/NEBULOUS5.jpg'

import roulette from './../../assets/images/portfolio/Roulette Signage/ROULETTE_SIGNAGE1.jpg'
import roulette2 from './../../assets/images/portfolio/Roulette Signage/ROULETTE_SIGNAGE2.jpg'

import spikanunksRun from './../../assets/images/portfolio/Spikanunk’s Run/SPIKANUNK1.jpg'
import spikanunksRun2 from './../../assets/images/portfolio/Spikanunk’s Run/SPIKANUNK2.jpg'
import spikanunksRun3 from './../../assets/images/portfolio/Spikanunk’s Run/SPIKANUNK3.jpg'
import spikanunksRun4 from './../../assets/images/portfolio/Spikanunk’s Run/SPIKANUNK4.jpg'
import spikanunksRun5 from './../../assets/images/portfolio/Spikanunk’s Run/SPIKANUNK5.jpg'

import stax from './../../assets/images/portfolio/Stax/stax1.jpg'
import stax2 from './../../assets/images/portfolio/Stax/stax2.jpg'
import stax3 from './../../assets/images/portfolio/Stax/stax3.jpg'

import tapThere from './../../assets/images/portfolio/TapThere/TAPTHERE1.jpg'

import twerkApp from './../../assets/images/portfolio/Twerk App/TWERK_APP1.jpg'
import twerkApp2 from './../../assets/images/portfolio/Twerk App/TWERK_APP2.jpg'
import twerkApp3 from './../../assets/images/portfolio/Twerk App/TWERK_APP3.jpg'
import twerkApp4 from './../../assets/images/portfolio/Twerk App/TWERK_APP4.jpg'

import ddr from './../../assets/images/portfolio/DDR Pocket Edition/DDR_POCKET1.jpg'
import ddr2 from './../../assets/images/portfolio/DDR Pocket Edition/DDR_POCKET2.jpg'
import ddr3 from './../../assets/images/portfolio/DDR Pocket Edition/DDR_POCKET3.jpg'
import ddr4 from './../../assets/images/portfolio/DDR Pocket Edition/DDR_POCKET4.jpg'
import ddr5 from './../../assets/images/portfolio/DDR Pocket Edition/DDR_POCKET5.jpg'

import gunsOfLegend from './../../assets/images/portfolio/guns of legend/GUNS_OF_LEGENDS1.jpg'
import gunsOfLegend2 from './../../assets/images/portfolio/guns of legend/GUNS_OF_LEGENDS2.jpg'
import gunsOfLegend3 from './../../assets/images/portfolio/guns of legend/GUNS_OF_LEGENDS3.jpg'
import gunsOfLegend4 from './../../assets/images/portfolio/guns of legend/GUNS_OF_LEGENDS4.jpg'
import gunsOfLegend5 from './../../assets/images/portfolio/guns of legend/GUNS_OF_LEGENDS5.jpg'

import baccarat from './../../assets/images/portfolio/bacarat.jpg'
import baccarat2 from './../../assets/images/portfolio/iScore Plus Baccarat/iSCORE_PLUS_BARRACAT2_COMBO.jpg'

import projectX from './../../assets/images/portfolio/ProjectX.png'
import projectY from './../../assets/images/portfolio/ProjectY.png'

import android from './../../assets/images/platforms/ico-platform-android-blue.svg'
import apple from './../../assets/images/platforms/ico-platform-apple-blue.svg'
import ps from './../../assets/images/platforms/ico-platform-ps-blue.svg'
import steam from './../../assets/images/platforms/ico-platform-steam-blue.svg'
import windows from './../../assets/images/platforms/ico-platform-windows-blue.svg'
import xbox from './../../assets/images/platforms/ico-xbox-blue.svg'

const INITIAL_STATE = {
	inDevelopment: false,
	appDevelopment: false,
	customHardware: false,
	gameDevelopment: false,
	all: false,
	isOpen: false,

	listOfGames: {
		settings: {
			fade: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					fade: true,
					infinite: true,
					speed: 500,
					slidesToShow: 1,
					slidesToScroll: 1,
				  }
				},
			]
		}
	},
}

class PortfolioComponent extends Component {
	constructor(props) {
		super(props)

		this.myRef = React.createRef();
		this.state = { ...INITIAL_STATE }

		this.showGames = this.showGames.bind(this);
		this.scrollToMyRef = this.scrollToMyRef.bind(this);
		this.offset = this.offset.bind(this);
		this.clickMe = this.clickMe.bind(this);
		this.openModal = this.openModal.bind(this);
	}

	openModal () {
		debugger
		this.setState({isOpen: true})
	}

	openModal (id) {
		this.setState({
			isOpen: {
				[id]: true
			}
		});
	}
	

	scrollToMyRef(game) {
		const data = document.querySelector(game);
		if (data !== null) {
			let position = this.offset(data);
			window.scrollTo(position.left - 100, position.top - 100);
		}
		
	}

	offset (el) {
		var rect = el.getBoundingClientRect(),
		scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
		scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
	}

	clickMe(event) {
		event.preventDefault();
		var game = `.${event.target.getAttribute('alt')}`;
		this.scrollToMyRef(game);
	}

	componentDidMount = () => {
		ReactGA.initialize('UA-172432218-1')
		ReactGA.event({
			category: 'page',
			action: 'User has entered at portfolio page'
		})

		this.showGames('all');
	}

	showGames(game) {
		if (game && Object.keys(INITIAL_STATE).includes(game)) {
			const toggle = !this.state[game];
			this.setState({...INITIAL_STATE, [game]: toggle });
		}
	}

	render() {
		const { inDevelopment, appDevelopment, customHardware, gameDevelopment, all, listOfGames  } = this.state;
		return (
			<>
				<Container fluid id="portfolio-component">
					<Row className="our-awesome-projects full-width">
						<div className="bg-grey-part-one"></div>
						<Row className="namazu-g">
						<Col xs="12" className="text-center">
							<h2>FEATURED GAMES</h2>
						</Col>
						<Col xs="6" md="3">
							<div className="p-2 text-center" onClick={(e) => this.clickMe(e)}>
								<img src={featured1} className="img-fluid mx-auto" alt="drop-squad" />
							</div>
						</Col>
						<Col xs="6" md="3">
							<div className="p-2 text-center" onClick={this.clickMe}>
								<img src={featured2} className="img-fluid mx-auto" alt="stax" />
							</div>
						</Col>
						<Col xs="6" md="3">
							<div className="p-2 text-center" onClick={this.clickMe}>
								<img src={featured3} className="img-fluid mx-auto" alt="nebulous" />
							</div>
						</Col>
						<Col xs="6" md="3">
							<div className="p-2 text-center" onClick={this.clickMe}>
								<img src={featured4} className="img-fluid mx-auto" alt="ddr" />
							</div>
						</Col>
						</Row>
						<div className="bg-grey-part-two"></div>
					</Row>
					<Row className="filters">
						<Col xs="6" md="2" className="column">
							<div className="p-2 text-center">
								<button block outline color="secondary" className={inDevelopment ? 'active button-brush-1': 'button-brush-1'}  onClick={() => this.showGames("inDevelopment")}>In Development</button>
							</div>
						</Col>
						<Col xs="6" md="2" className="column">
							<div className="p-2 text-center">
								<button block outline color="secondary" className={appDevelopment ? 'active button-brush-1': 'button-brush-1'}  onClick={() => this.showGames("appDevelopment")}>App Development</button>
							</div>
						</Col>
						<Col xs="6" md="2" className="column">
							<div className="p-2 text-center">
								<button block outline color="secondary" className={customHardware ? 'active button-brush-1': 'button-brush-1'}  onClick={() => this.showGames("customHardware")}>Custom Hardware</button>
							</div>
						</Col>
						<Col xs="6" md="2" className="column">
							<div className="p-2 text-center">
								<button block outline color="secondary" className={gameDevelopment ? 'active button-brush-1': 'button-brush-1'}  onClick={() => this.showGames("gameDevelopment")}>Game Development</button>
							</div>
						</Col>
						<Col xs="6" md="2" className="column">
							<div className="p-2 text-center">
								<button block outline color="secondary" className={all ? 'active button-brush-1': 'button-brush-1'}  onClick={() => this.showGames("all")}>All</button>
							</div>
						</Col>
					</Row>
					<Row className="games-filtered">
						{appDevelopment &&
							<Row>
								<Col xs="12" md="5">
								<div className="p-5">
									<img src={projectX} alt="game 1" />
								</div>
								</Col>
								<Col xs="12" md="5">
									<div className="description">
										<h2>PROJECT X</h2>
										<p>Currently under development, Namazu's team is providing Data Engineering and Scalable Architecture for a company in the automotive accessory industry. The team has been responsible for developing and delivering the following:</p>
										<ul>
											<li>Data engineering for ingestion, processing, and analytics of raw location-based IoT data.</li>
											<li>Real-time analytics of roadway traffic using data gathered from the company's proprietary IoT-based sensor network.</li>
											<li>A serverless and fully scalable RESTful API for communication with both devices and mobile applications.</li>
											<li>Supporting outside vendors in the development of client applications.</li>
											<li>Deployments, upgrades, and live DevOps Support</li>
										</ul>
									</div>
								</Col>
								<Col xs="12" md="5">
									<div className="p-5">
										<img src={projectY} alt="game 2" />
									</div>
								</Col>
								<Col xs="12" md="5">
								<div className="p-5">
									<h2>PROJECT Y</h2>
										<p>Currently under development. Namazu's team is responsible for developing the firmware communication architecture and SDK for a gaming-focused IoT device. The team is responsible for the development and SDK for the product. The team has developed the following so far:</p>
										<ul>
											<li>AI and Machine Learning algorithms for image and pattern recognition.</li>
											<li>Cross-platform native libraries built in C/C++ supporting Windows, OSX, Linux, Android, and iOS with a common API</li>
											<li>A proprietary communication protocol allowing game developers to interface their titles with devices in the field.</li>
											<li>Unity3D plugins to provide wrappers around the native C/C++ Code.</li>
											<li>DRM and anti-counterfeiting measures for the IoT devices.</li>
										</ul>
								</div>
								</Col>

								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={indycar} alt="game 2" />
										<img src={indycar2} alt="game 2" />
										<img src={indycar3} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
								<div className="description">
									<h2>INDYCAR</h2>
									<p><strong>Studio:</strong> Quickplay / Verizon</p>
									<p><strong>Summary:</strong> Race fans are in the driver’s seat with INDYCAR Mobile, as they take control by selecting their favorite driver or team. Featuring live streaming in-car camera feeds and radio broadcast. The INDYCAR Mobile App has evolved to become essential to INDYCAR fans.</p>
									<p><strong>Our Focus:</strong> Front-end engineering for iOS version. Improved UI design over existing Android version. Integrated with RESTful and live video stream servers. Social media services integration</p>
									<p><strong>Platform:</strong> iOS</p>
									<div className="row">
										<div className="col-sm-12">
										
										<ModalVideo channel='youtube' isOpen={this.state.isOpen[1]} videoId='O_e6hookFCg' onClose={() => this.setState({isOpen: false})} />
											<button onClick= {this.openModal.bind(this, 1)} className="button-brush-1 link-button">watch trailer</button>
											<a className="link-button" href="https://itunes.apple.com/us/app/indycar-14/id606905722?mt=8">
												<img src={apple} />
											</a>
										</div>
									</div>
								</div>
								</Col>

								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={twerkApp} alt="game 2" />
										<img src={twerkApp2} alt="game 2" />
										<img src={twerkApp3} alt="game 2" />
										<img src={twerkApp4} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
								<div className="description">
									<h2>Twerk App by Big Freedia</h2>
									<p><strong>Studio:</strong> Mystic Coders / BitCandy Music Filter</p>
									<p><strong>Summary:</strong> Fans of Big Freedia make outrageous twerk videos with the free Big Freedia Twerk App. Players record themselves using the app, along with Big Freedia’s critically acclaimed music, and the app will automatically composite Big Freedia and her dancers into the video.</p>
									<p><strong>Our Focus:</strong> Native Android app that composites user recorded video with music and greenscreen video, placing the user in a scene with Big Freedia and dancers. Support for sharing videos to YouTube and Facebook.</p>
									<p><strong>Platform:</strong> Android</p>
									<div className="row">
										<div className="col-sm-12">
											<ModalVideo channel='youtube' isOpen={this.state.isOpen[2]}  videoId='a4cCPeK1Rdk' onClose={() => this.setState({isOpen: false})} />
											<button onClick= {this.openModal.bind(this, 2)} className="button-brush-1 link-button">watch trailer</button>
										</div>
									</div>
								</div>
								</Col>
							</Row>
						}

						{inDevelopment &&
							<Row>
								<Col xs="12" md="5">
								<div className="p-5">
									<img src={projectX} alt="game 1" />
								</div>
								</Col>
								<Col xs="12" md="5">
									<div className="p-5">
										<h2>PROJECT X</h2>
										<p>Currently under development, Namazu's team is providing Data Engineering and Scalable Architecture for a company in the automotive accessory industry. The team has been responsible for developing and delivering the following:</p>
										<ul>
											<li>Data engineering for ingestion, processing, and analytics of raw location-based IoT data.</li>
											<li>Real-time analytics of roadway traffic using data gathered from the company's proprietary IoT-based sensor network.</li>
											<li>A serverless and fully scalable RESTful API for communication with both devices and mobile applications.</li>
											<li>Supporting outside vendors in the development of client applications.</li>
											<li>Deployments, upgrades, and live DevOps Support</li>
										</ul>
									</div>
								</Col>
								<Col xs="12" md="5">
									<div className="p-5">
										<img src={projectY} alt="game 2" />
									</div>
								</Col>
								<Col xs="12" md="5">
								<div className="p-5">
									<h2>PROJECT Y</h2>
										<p>Currently under development. Namazu's team is responsible for developing the firmware communication architecture and SDK for a gaming-focused IoT device. The team is responsible for the development and SDK for the product. The team has developed the following so far:</p>
										<ul>
											<li>AI and Machine Learning algorithms for image and pattern recognition.</li>
											<li>Cross-platform native libraries built in C/C++ supporting Windows, OSX, Linux, Android, and iOS with a common API</li>
											<li>A proprietary communication protocol allowing game developers to interface their titles with devices in the field.</li>
											<li>Unity3D plugins to provide wrappers around the native C/C++ Code.</li>
											<li>DRM and anti-counterfeiting measures for the IoT devices.</li>
										</ul>
								</div>
								</Col>
							</Row>
						}

						{customHardware &&
							<Row>
								<Col xs="12" md="5">
									<div className="p-5">
										<img src={projectY} alt="game 2" />
									</div>
								</Col>
								<Col xs="12" md="5">
								<div className="p-5">
									<h2>PROJECT Y</h2>
										<p>Currently under development. Namazu's team is responsible for developing the firmware communication architecture and SDK for a gaming-focused IoT device. The team is responsible for the development and SDK for the product. The team has developed the following so far:</p>
										<ul>
											<li>AI and Machine Learning algorithms for image and pattern recognition.</li>
											<li>Cross-platform native libraries built in C/C++ supporting Windows, OSX, Linux, Android, and iOS with a common API</li>
											<li>A proprietary communication protocol allowing game developers to interface their titles with devices in the field.</li>
											<li>Unity3D plugins to provide wrappers around the native C/C++ Code.</li>
											<li>DRM and anti-counterfeiting measures for the IoT devices.</li>
										</ul>
								</div>
								</Col>

								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={stax} alt="game 2" />
										<img src={stax2} alt="game 2" />
										<img src={stax3} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
									<div className="description">
										<h2>STAX</h2>
										<p><strong>Studio:</strong> AGS</p>
										<p><strong>Summary:</strong> Stax is an award-winning casino game display offering multiple progressive meters to the players at once. Each meter updates independently with its own win criteria. The Stax sign can be configured with a multitude of games and aesthetic themes. Prizes, winning hands, pay tables, etc., can be set to any arbitrary value.</p>
										<p><strong>Our Focus:</strong> As the core developer, Namazu generated all server connectivity for communication with the dealer terminal, configuration, art, transition effects, and celebration animations. With interactive signage, we pushed the interaction between the screen and playing tables to increase engagement and gaming values.</p>
										<p><strong>Platform:</strong> Custom Hardware</p>
										<div className="row">
											<div className="col-sm-12">
												<ModalVideo channel='youtube' isOpen={this.state.isOpen[3]}  videoId='axCWBUQPorM' onClose={() => this.setState({isOpen: false})} />
												<button onClick= {this.openModal.bind(this, 3)} className="button-brush-1 link-button">watch trailer</button>
												
												<a href="http://playags.com/portfolio/stax/" className="link-button">
													<button className="button-brush-1">visit site</button>
												</a>
											</div>
										</div>
									</div>
								</Col>
								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={bonusSpin} alt="game 2" />
										<img src={bonusSpin2} alt="game 2" />
										<img src={bonusSpin3} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
								<div className="description">
									<h2>BONUS SPIN</h2>
									<p><strong>Studio:</strong> AGS</p>
									<p><strong>Summary:</strong> Bonus Spin is a customizable virtual prize wheel that connects to any table game. The casino can offer anything as the top award - whether it’s a high-paying progressive, fixed cash, or an experience-based prize.</p>
									<p><strong>Our Focus:</strong> Developed casino game signage in Unity. Reacts to sensor data, displaying results and prizes on signage. Made game type, prizes, and appearance configurable via server interface. Developed network connectivity for game to be driven by dealer terminal.</p>
									<p><strong>Platform:</strong> Custom Hardware</p>
									<div className="row">
										<div className="col-sm-12">
											<ModalVideo channel='youtube' isOpen={this.state.isOpen[4]}  videoId='bj4kRVnnryM' onClose={() => this.setState({isOpen: false})} />
											<button onClick= {this.openModal.bind(this, 4)} className="button-brush-1 link-button">watch trailer</button>

											<a href="http://playags.com/portfolio/bonus-spin-blackjack/" className="link-button">
												<button className="button-brush-1">visit site</button>
											</a>
										</div>
									</div>
								</div>
								</Col>
								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={baccarat} alt="game 2" />
										<img src={baccarat2} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
								<div className="description">
									<h2>iScore Plus Baccarat</h2>
									<p><strong>Studio:</strong> Shuffle Master / Bally’s / Scientific Game</p>
									<p><strong>Summary:</strong> Display baccarat game results and trends in real time. iScore Plus features a large display and multiple new options and upgrades. It works seamlessly with the i-Shoe Auto intelligent shoe and with Safe-Bacc to provide instantaneous screen updates without requiring manual data input by the dealer.</p>
									<p><strong>Our Focus:</strong> Lead development of user interfaces for two Bally Technologies products, iScore Baccarat. The focus and ambition was to bring to life fantastic interactive signage for a great product, pushing the interaction between the screen and the actual playing tables to increase engagement and gaming values.</p>
									<p><strong>Platform:</strong> Custom Hardware</p>
									<div className="row">
										<div className="col-sm-12">
											<ModalVideo channel='youtube' isOpen={this.state.isOpen[5]}  videoId='bj4kRVnnryM' onClose={() => this.setState({isOpen: false})} />
											<button onClick= {this.openModal.bind(this, 5)} className="button-brush-1 link-button">watch trailer</button>
											
											<a className="link-button" href="https://www.sggaming.com/Games/Shuffle-Master/Shufflers-and-Utilities/Scoreboards/iScore-Plus-7810">
												<button className="button-brush-1">visit site</button>
											</a>
										</div>
									</div>
								</div>
								</Col>

								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={fusion} alt="game 2" />
										<img src={fusion2} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
								<div className="description">
									<h2>Fusion Auto Roulette</h2>
									<p><strong>Studio:</strong> Shuffle Master / Bally’s / Scientific Games</p>
									<p><strong>Summary:</strong> Roulette with a fully configurable, live-action layout. The display board offers statistics on table happenings and a camera displays the live ball drop on-screen. Players use touchscreens to place their bets and view payouts. The game allows customization to offer unique experiences.</p>
									<p><strong>Our Focus:</strong> Developed casino game signage in Unity3D, providing support and consulting for Bally’s senior engineering staff. Built to interact with table hardware such as roulette wheel to display table results with exciting graphics and video.</p>
									<p><strong>Platform:</strong> Custom Hardware</p>

									<ModalVideo channel='youtube' isOpen={this.state.isOpen[6]}  videoId='mwoyAzfm7eI' onClose={() => this.setState({isOpen: false})} />
									<button onClick= {this.openModal.bind(this, 6)} className="button-brush-1 link-button">watch trailer</button>
									
									<a className="link-button" href="https://www.sggaming.com/Games/Shuffle-Master/Electronic-Tables/Fusion/Fusion-Auto-Roulette-6627">
										<button className="button-brush-1">visit site</button>
									</a>
								</div>
								</Col>

								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={roulette} alt="game 2" />
										<img src={roulette2} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
								<div className="description">
									<h2>Roulette Signage</h2>
									<p><strong>Studio:</strong> AGS</p>
									<p><strong>Summary:</strong> Double-sided 29 roulette signage display with rich features. Couples playful roulette ball animations with detailed roulette trends. Alternate with marketing video content and idle game attract mode. The display has fully customizable layout, theme, and logo, as well as adjustable min/max limits.</p>
									<p><strong>Our Focus:</strong> Developed casino game signage in Unity. Overlayed statistical game data over dynamic 3D roulette table and synced character animations to winning numbers. Made game type, prizes, and appearance configurable via server interface. Designed display to be fully modular with customizable zones and automatic sizing.</p>
									<p><strong>Platform:</strong> Custom Hardware</p>

									<ModalVideo channel='youtube' isOpen={this.state.isOpen[7]}  videoId='hwQEWbW-Nxw' onClose={() => this.setState({isOpen: false})} />
									<button onClick= {this.openModal.bind(this, 7)} className="button-brush-1 link-button">watch trailer</button>
									
									<a href="http://playags.com/portfolio/roulette-signage/" className="link-button">
										<button className="button-brush-1">visit site</button>
									</a>
								</div>
								</Col>
							</Row>
						}

						{gameDevelopment &&
							<Row>
								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={pivot} alt="game 2" />
										<img src={pivot2} alt="game 2" />
										<img src={pivot3} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
								<div className="description">
									<h2>Drop Squad™: The Escape</h2>
									<p><strong>Studio:</strong> Bush League Games</p>
									<p><strong>Summary:</strong> Solve physics puzzles and bounce the lab mice to freedom! Pivot the ramps, drop the ball and bounce through pegs to complete these strategic challenges. Fun for all ages!</p>
									<p><strong>Our Focus:</strong> Physics-based puzzle game with head-to-head multiplayer, developed in Unity. Backend powered by Elements. 90 single-player puzzle levels, multiplayer matchmaking, with embedded Amazon GameOn tournaments</p>
									<p><strong>Platform:</strong> iOS and Android</p>
									<div className="row">
										<div className="col-sm-12">
											<ModalVideo channel='youtube' isOpen={this.state.isOpen[8]}  videoId='oUR0yYjZUTw' onClose={() => this.setState({isOpen: false})} />
											<button onClick= {this.openModal.bind(this, 8)} className="button-brush-1 link-button">watch trailer</button>
											
											<a className="link-button" href="http://bushleague.com/games/pivot-2-win-it">
												<button className="button-brush-1">visit site</button>
											</a>
											<a className="link-button" href="https://itunes.apple.com/US/app/id1321079806?mt=8">
												<img src={apple} />
											</a>
											<a className="link-button" href="https://play.google.com/store/apps/details?id=com.bushleague.ptwi&hl=en_US">
												<img src={android} />
											</a>
										</div>
									</div>
								</div>
								</Col>
								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={nebulous} alt="game 2" />
										<img src={nebulous2} alt="game 2" />
										<img src={nebulous3} alt="game 2" />
										<img src={nebulous4} alt="game 2" />
										<img src={nebulous5} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
								<div className="description">
									<h2>Nebulous</h2>
									<p><strong>Studio:</strong> Namazu Studios</p>
									<p><strong>Summary:</strong> Help guide Commander Dash Johnson, a trapped astronaut, through maze-like levels, seeking the next wormhole to his eventual freedom. Arrange the pieces of the puzzle so that when he falls, gravity and momentum guide him safely (and probably painfully) to the end.</p>
									<p><strong>Our Focus:</strong> 3D physics-based puzzle game developed in Unity. 40 challenging levels & fully voiced dialogue. VR support with special hands-free controls.</p>
									<p><strong>Platform:</strong> PS4 & PSVR, XBox One, PC, Mac, Oculus</p>
									<div className="row">
										<div className="col-sm-12">
											<ModalVideo channel='youtube' isOpen={this.state.isOpen[9]}  videoId='R5PsY7Hn3hs' onClose={() => this.setState({isOpen: false})} />
											<button onClick= {this.openModal.bind(this, 9)} className="button-brush-1 link-button">watch trailer</button>
											
											<a className="link-button" href="https://store.steampowered.com/app/370140/Nebulous/">
												<img src={steam} />
											</a>
											<a className="link-button" href="https://store.playstation.com/en-us/product/UP2140-CUSA02087_00-NEBULOUS00000001">
												<img src={ps} />
											</a>
											<a className="link-button" href="https://www.microsoft.com/en-us/p/nebulous/bnc55p9wc4xx">
												<img src={xbox} />
											</a>
										</div>
									</div>									
								</div>
								</Col>
								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={vitaminZ} alt="game 2" />
										<img src={vitaminZ2} alt="game 2" />
										<img src={vitaminZ3} alt="game 2" />
										<img src={vitaminZ4} alt="game 2" />
										<img src={vitaminZ5} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
								<div className="description">
									<h2>Vitamin Z</h2>
									<p><strong>Studio:</strong> Namazu Studios</p>
									<p><strong>Summary:</strong> Vitamin Z is an action packed, free-to-play game that will have you on the edge of your seat! Blow up zombie fruits and veggies, earning precious Z that you will use to upgrade your gear and delve deeper into the besieged lab. Earn trophies, complete missions, and conquer the leaderboards!</p>
									<p><strong>Our Focus:</strong> 3D action game developed in Unity. Free-to-play defensive stationary shooter. Twelve enemy types, multiple arenas, special weapons and upgrade.</p>
									<p><strong>Platform:</strong> PS Vita</p>
									<div className="row">
										<div className="col-sm-12">
											<ModalVideo channel='youtube' isOpen={this.state.isOpen[10]}  videoId='oUR0yYjZUTw' onClose={() => this.setState({isOpen: false})} />
											<button onClick= {this.openModal.bind(this, 10)} className="button-brush-1 link-button">watch trailer</button>
											
											<a className="link-button" href="https://store.playstation.com/en-us/product/UP2140-PCSE00506_00-VITAMINZ00000001">
												<img src={ps} />
											</a>		
										</div>
									</div>
								</div>
								</Col>

								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={ddr} alt="game 2" />
										<img src={ddr2} alt="game 2" />
										<img src={ddr3} alt="game 2" />
										<img src={ddr4} alt="game 2" />
										<img src={ddr5} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
								<div className="description">
									<h2>DDR Pocket Edition</h2>
									<p><strong>Studio:</strong> Konami and Namazu Studios</p>
									<p><strong>Summary:</strong> Customize your workout by selecting the difficulty and time duration. DDR Pocket Edition tracks your progress and displays your calories burned, steps taken, and your total workout time. Play through over 300 different songs to help kick start your workout routine. DDR Pocket Edition makes getting healthier easy, convenient, and fun.</p>
									<p><strong>Our Focus:</strong> Namazu and Konami co-branded & collaborated to help players discover a fresh new way to get active with DanceDanceRevolution Pocket Edition. Strong producers and developers from both teams took part in the creation.</p>
									<p><strong>Platform:</strong> iOS and AppleTV</p>

									<ModalVideo channel='youtube' isOpen={this.state.isOpen[11]}  videoId='2QnudMd00nk' onClose={() => this.setState({isOpen: false})} />
									<button onClick= {this.openModal.bind(this, 11)} className="button-brush-1 link-button">watch trailer</button>
									
									<a className="link-button" href="https://www.fitness-gaming.com/news/home-fitness/ddr-pocket-edition.html">
										<button className="button-brush-1">visit site</button>
									</a>
								</div>
								</Col>

								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={iDuel} alt="game 2" />
										<img src={iDuel2} alt="game 2" />
										<img src={iDuel3} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
								<div className="description">
									<h2>iDuel Online</h2>
									<p><strong>Studio:</strong> Namazu Studios</p>
									<p><strong>Summary:</strong> Create a unique character and duel other players LIVE in this FREE massively multiplayer online game on your iPhone and iPod Touch! Fight your way up the leaderboards and deck out your character in totally sweet gear! Gear changes the way your character looks, boosts stats, and contains powerful special abilities!</p>
									<p><strong>Our Focus:</strong> Free-to-play online turn-based dueling MMOG. Native iOS client, custom server. Live strategic RPG-style battles.</p>
									<p><strong>Platform:</strong> iOS</p>

									<ModalVideo channel='youtube' isOpen={this.state.isOpen[12]}  videoId='PRvwRzjm2_Y' onClose={() => this.setState({isOpen: false})} />
									<button onClick= {this.openModal.bind(this, 12)} className="button-brush-1 link-button">watch trailer</button>
								</div>
								</Col>

								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={spikanunksRun} alt="game 2" />
										<img src={spikanunksRun2} alt="game 2" />
										<img src={spikanunksRun3} alt="game 2" />
										<img src={spikanunksRun4} alt="game 2" />
										<img src={spikanunksRun5} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
								<div className="description">
									<h2>Spikanunk’s Run</h2>
									<p><strong>Studio:</strong> Kidlandia</p>
									<p><strong>Summary:</strong> Spikanunk’s Run was directly linked to social media for players to share their accomplishments and glory. Players could also purchase items and rewards within the app for added gameplay value.</p>
									<p><strong>Our Focus:</strong> With characters provided by Kidlandia, Namazu designed, illustrated, programmed and developed the entire game. Built an ad-free, endearing and rewarding game that was inline with an already-existing world, characters, and brand for young players.</p>
									<p><strong>Platform:</strong> iOS</p>
								</div>
								</Col>

								<Col xs="12" md="5" className="p-5">
									<Slider {...listOfGames.settings}>
										<img src={gunsOfLegend} alt="game 2" />
										<img src={gunsOfLegend2} alt="game 2" />
										<img src={gunsOfLegend3} alt="game 2" />
										<img src={gunsOfLegend4} alt="game 2" />
										<img src={gunsOfLegend5} alt="game 2" />
									</Slider>
								</Col>
								<Col xs="12" md="5">
								<div className="description">
									<h2>Guns of Legend</h2>
									<p><strong>Studio:</strong> Namazu Studios</p>
									<p><strong>Summary:</strong> In Guns of Legend, Victor Kasara is thrust into a top secret infiltration mission into the heart of the Sorden territories to stop a plan to sabotage the peace treaty with the Tristessa Protectorate Union. Kasara is a brash, young tank ace, and after this adventure will become a legendary mobile weapons tank pilot.</p>
									<p><strong>Our Focus:</strong> Classic Artillery gameplay unites with story-driven, turn-based action. Humorous presentation with colorful, appealing visual design. Campaign mode or a single, fast match in Quick Play mode. Head-to-head local multiplayer matches.</p>
									<p><strong>Platform:</strong> Android</p>
								</div>
								</Col>
							</Row>
						}
						{all &&
						<Row>
							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={pivot1} alt="game 2" />
									<img src={pivot2} alt="game 2" />
									<img src={pivot3} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
							<div className="description drop-squad">
								<h2>Drop Squad™: The Escape</h2>
								<p><strong>Studio:</strong> Bush League Games</p>
								<p><strong>Summary:</strong> Solve physics puzzles and bounce the lab mice to freedom! Pivot the ramps, drop the ball and bounce through pegs to complete these strategic challenges. Fun for all ages!</p>
								<p><strong>Our Focus:</strong> Physics-based puzzle game with head-to-head multiplayer, developed in Unity. Backend powered by Elements. 90 single-player puzzle levels, multiplayer matchmaking, with embedded Amazon GameOn tournaments</p>
								<p><strong>Platform:</strong> iOS and Android</p>
								<div className="row">
									<div className="col-sm-12">
										<ModalVideo channel='youtube' isOpen={this.state.isOpen[13]}  videoId='oUR0yYjZUTw' onClose={() => this.setState({isOpen: false})} />
										<button onClick= {this.openModal.bind(this, 13)} className="button-brush-1 link-button">watch trailer</button>
										
										<a className="link-button" href="http://bushleague.com/games/pivot-2-win-it">
											<button className="button-brush-1">visit site</button>
										</a>
										<a className="link-button" href="https://itunes.apple.com/US/app/id1321079806?mt=8">
											<img src={apple} />
										</a>
										<a className="link-button" href="https://play.google.com/store/apps/details?id=com.bushleague.ptwi&hl=en_US">
											<img src={android} />
										</a>
									</div>
								</div>
							</div>
							</Col>
							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={nebulous} alt="game 2" />
									<img src={nebulous2} alt="game 2" />
									<img src={nebulous3} alt="game 2" />
									<img src={nebulous4} alt="game 2" />
									<img src={nebulous5} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
							<div className="description nebulous">
								<h2>Nebulous</h2>
								<p><strong>Studio:</strong> Namazu Studios</p>
								<p><strong>Summary:</strong> Help guide Commander Dash Johnson, a trapped astronaut, through maze-like levels, seeking the next wormhole to his eventual freedom. Arrange the pieces of the puzzle so that when he falls, gravity and momentum guide him safely (and probably painfully) to the end.</p>
								<p><strong>Our Focus:</strong> 3D physics-based puzzle game developed in Unity. 40 challenging levels & fully voiced dialogue. VR support with special hands-free controls.</p>
								<p><strong>Platform:</strong> PS4 & PSVR, XBox One, PC, Mac, Oculus</p>
								<div className="row">
									<div className="col-sm-12">
										<ModalVideo channel='youtube' isOpen={this.state.isOpen[14]}  videoId='R5PsY7Hn3hs' onClose={() => this.setState({isOpen: false})} />
										<button onClick= {this.openModal.bind(this, 14)} className="button-brush-1 link-button">watch trailer</button>
										
										<a className="link-button" href="https://store.steampowered.com/app/370140/Nebulous/">
											<img src={steam} />
										</a>
										<a className="link-button" href="https://store.playstation.com/en-us/product/UP2140-CUSA02087_00-NEBULOUS00000001">
											<img src={ps} />
										</a>
										<a className="link-button" href="https://www.microsoft.com/en-us/p/nebulous/bnc55p9wc4xx">
											<img src={xbox} />
										</a>
									</div>
								</div>									
							</div>
							</Col>
							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={vitaminZ} alt="game 2" />
									<img src={vitaminZ2} alt="game 2" />
									<img src={vitaminZ3} alt="game 2" />
									<img src={vitaminZ4} alt="game 2" />
									<img src={vitaminZ5} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
							<div className="description">
								<h2>Vitamin Z</h2>
								<p><strong>Studio:</strong> Namazu Studios</p>
								<p><strong>Summary:</strong> Vitamin Z is an action packed, free-to-play game that will have you on the edge of your seat! Blow up zombie fruits and veggies, earning precious Z that you will use to upgrade your gear and delve deeper into the besieged lab. Earn trophies, complete missions, and conquer the leaderboards!</p>
								<p><strong>Our Focus:</strong> 3D action game developed in Unity. Free-to-play defensive stationary shooter. Twelve enemy types, multiple arenas, special weapons and upgrade.</p>
								<p><strong>Platform:</strong> PS Vita</p>
								<div className="row">
									<div className="col-sm-12">
										<ModalVideo channel='youtube' isOpen={this.state.isOpen[15]}  videoId='oUR0yYjZUTw' onClose={() => this.setState({isOpen: false})} />
										<button onClick= {this.openModal.bind(this, 15)} className="button-brush-1 link-button">watch trailer</button>
										
										<a className="link-button" href="https://store.playstation.com/en-us/product/UP2140-PCSE00506_00-VITAMINZ00000001">
											<img src={ps} />
										</a>		
									</div>
								</div>
							</div>
							</Col>

							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={ddr} alt="game 2" />
									<img src={ddr2} alt="game 2" />
									<img src={ddr3} alt="game 2" />
									<img src={ddr4} alt="game 2" />
									<img src={ddr5} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
							<div className="description ddr">
								<h2>DDR Pocket Edition</h2>
								<p><strong>Studio:</strong> Konami and Namazu Studios</p>
								<p><strong>Summary:</strong> Customize your workout by selecting the difficulty and time duration. DDR Pocket Edition tracks your progress and displays your calories burned, steps taken, and your total workout time. Play through over 300 different songs to help kick start your workout routine. DDR Pocket Edition makes getting healthier easy, convenient, and fun.</p>
								<p><strong>Our Focus:</strong> Namazu and Konami co-branded & collaborated to help players discover a fresh new way to get active with DanceDanceRevolution Pocket Edition. Strong producers and developers from both teams took part in the creation.</p>
								<p><strong>Platform:</strong> iOS and AppleTV</p>

								<ModalVideo channel='youtube' isOpen={this.state.isOpen[16]}  videoId='2QnudMd00nk' onClose={() => this.setState({isOpen: false})} />
								<button onClick= {this.openModal.bind(this, 16)} className="button-brush-1 link-button">watch trailer</button>
								
								<a className="link-button" href="https://www.fitness-gaming.com/news/home-fitness/ddr-pocket-edition.html">
									<button className="button-brush-1">visit site</button>
								</a>
							</div>
							</Col>

							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={iDuel} alt="game 2" />
									<img src={iDuel2} alt="game 2" />
									<img src={iDuel3} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
							<div className="description">
								<h2>iDuel Online</h2>
								<p><strong>Studio:</strong> Namazu Studios</p>
								<p><strong>Summary:</strong> Create a unique character and duel other players LIVE in this FREE massively multiplayer online game on your iPhone and iPod Touch! Fight your way up the leaderboards and deck out your character in totally sweet gear! Gear changes the way your character looks, boosts stats, and contains powerful special abilities!</p>
								<p><strong>Our Focus:</strong> Free-to-play online turn-based dueling MMOG. Native iOS client, custom server. Live strategic RPG-style battles.</p>
								<p><strong>Platform:</strong> iOS</p>

								<ModalVideo channel='youtube' isOpen={this.state.isOpen[17]}  videoId='PRvwRzjm2_Y' onClose={() => this.setState({isOpen: false})} />
								<button onClick= {this.openModal.bind(this, 17)} className="button-brush-1 link-button">watch trailer</button>
							</div>
							</Col>

							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={spikanunksRun} alt="game 2" />
									<img src={spikanunksRun2} alt="game 2" />
									<img src={spikanunksRun3} alt="game 2" />
									<img src={spikanunksRun4} alt="game 2" />
									<img src={spikanunksRun5} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
							<div className="description">
								<h2>Spikanunk’s Run</h2>
								<p><strong>Studio:</strong> Kidlandia</p>
								<p><strong>Summary:</strong> Spikanunk’s Run was directly linked to social media for players to share their accomplishments and glory. Players could also purchase items and rewards within the app for added gameplay value.</p>
								<p><strong>Our Focus:</strong> With characters provided by Kidlandia, Namazu designed, illustrated, programmed and developed the entire game. Built an ad-free, endearing and rewarding game that was inline with an already-existing world, characters, and brand for young players.</p>
								<p><strong>Platform:</strong> iOS</p>
							</div>
							</Col>

							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={gunsOfLegend} alt="game 2" />
									<img src={gunsOfLegend2} alt="game 2" />
									<img src={gunsOfLegend3} alt="game 2" />
									<img src={gunsOfLegend4} alt="game 2" />
									<img src={gunsOfLegend5} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
							<div className="description">
								<h2>Guns of Legend</h2>
								<p><strong>Studio:</strong> Namazu Studios</p>
								<p><strong>Summary:</strong> In Guns of Legend, Victor Kasara is thrust into a top secret infiltration mission into the heart of the Sorden territories to stop a plan to sabotage the peace treaty with the Tristessa Protectorate Union. Kasara is a brash, young tank ace, and after this adventure will become a legendary mobile weapons tank pilot.</p>
								<p><strong>Our Focus:</strong> Classic Artillery gameplay unites with story-driven, turn-based action. Humorous presentation with colorful, appealing visual design. Campaign mode or a single, fast match in Quick Play mode. Head-to-head local multiplayer matches.</p>
								<p><strong>Platform:</strong> Android</p>
							</div>
							</Col>

							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={stax} alt="game 2" />
									<img src={stax2} alt="game 2" />
									<img src={stax3} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
								<div className="description stax">
									<h2>STAX</h2>
									<p><strong>Studio:</strong> AGS</p>
									<p><strong>Summary:</strong> Stax is an award-winning casino game display offering multiple progressive meters to the players at once. Each meter updates independently with its own win criteria. The Stax sign can be configured with a multitude of games and aesthetic themes. Prizes, winning hands, pay tables, etc., can be set to any arbitrary value.</p>
									<p><strong>Our Focus:</strong> As the core developer, Namazu generated all server connectivity for communication with the dealer terminal, configuration, art, transition effects, and celebration animations. With interactive signage, we pushed the interaction between the screen and playing tables to increase engagement and gaming values.</p>
									<p><strong>Platform:</strong> Custom Hardware</p>
									<div className="row">
										<div className="col-sm-12">
											<ModalVideo channel='youtube' isOpen={this.state.isOpen[18]}  videoId='axCWBUQPorM' onClose={() => this.setState({isOpen: false})} />
											<button onClick= {this.openModal.bind(this, 18)} className="button-brush-1 link-button">watch trailer</button>
											
											<a href="http://playags.com/portfolio/stax/" className="link-button">
												<button className="button-brush-1">visit site</button>
											</a>
										</div>
									</div>
								</div>
							</Col>
							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={bonusSpin} alt="game 2" />
									<img src={bonusSpin2} alt="game 2" />
									<img src={bonusSpin3} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
							<div className="description">
								<h2>BONUS SPIN</h2>
								<p><strong>Studio:</strong> AGS</p>
								<p><strong>Summary:</strong> Bonus Spin is a customizable virtual prize wheel that connects to any table game. The casino can offer anything as the top award - whether it’s a high-paying progressive, fixed cash, or an experience-based prize.</p>
								<p><strong>Our Focus:</strong> Developed casino game signage in Unity. Reacts to sensor data, displaying results and prizes on signage. Made game type, prizes, and appearance configurable via server interface. Developed network connectivity for game to be driven by dealer terminal.</p>
								<p><strong>Platform:</strong> Custom Hardware</p>
								<div className="row">
									<div className="col-sm-12">
										<ModalVideo channel='youtube' isOpen={this.state.isOpen[19]}  videoId='bj4kRVnnryM' onClose={() => this.setState({isOpen: false})} />
										<button onClick= {this.openModal.bind(this, 19)} className="button-brush-1 link-button">watch trailer</button>
										
										<a href="http://playags.com/portfolio/bonus-spin-blackjack/" className="link-button">
											<button className="button-brush-1">visit site</button>
										</a>
									</div>
								</div>
							</div>
							</Col>
							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={baccarat} alt="game 2" />
									<img src={baccarat2} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
							<div className="description">
								<h2>iScore Plus Baccarat</h2>
								<p><strong>Studio:</strong> Shuffle Master / Bally’s / Scientific Game</p>
								<p><strong>Summary:</strong> Display baccarat game results and trends in real time. iScore Plus features a large display and multiple new options and upgrades. It works seamlessly with the i-Shoe Auto intelligent shoe and with Safe-Bacc to provide instantaneous screen updates without requiring manual data input by the dealer.</p>
								<p><strong>Our Focus:</strong> Lead development of user interfaces for two Bally Technologies products, iScore Baccarat. The focus and ambition was to bring to life fantastic interactive signage for a great product, pushing the interaction between the screen and the actual playing tables to increase engagement and gaming values.</p>
								<p><strong>Platform:</strong> Custom Hardware</p>
								<div className="row">
									<div className="col-sm-12">
										<ModalVideo channel='youtube' isOpen={this.state.isOpen[20]}  videoId='bj4kRVnnryM' onClose={() => this.setState({isOpen: false})} />
										<button onClick= {this.openModal.bind(this, 20)} className="button-brush-1 link-button">watch trailer</button>
										
										<a className="link-button" href="https://www.sggaming.com/Games/Shuffle-Master/Shufflers-and-Utilities/Scoreboards/iScore-Plus-7810">
											<button className="button-brush-1">visit site</button>
										</a>
									</div>
								</div>
							</div>
							</Col>

							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={fusion} alt="game 2" />
									<img src={fusion2} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
							<div className="description">
								<h2>Fusion Auto Roulette</h2>
								<p><strong>Studio:</strong> Shuffle Master / Bally’s / Scientific Games</p>
								<p><strong>Summary:</strong> Roulette with a fully configurable, live-action layout. The display board offers statistics on table happenings and a camera displays the live ball drop on-screen. Players use touchscreens to place their bets and view payouts. The game allows customization to offer unique experiences.</p>
								<p><strong>Our Focus:</strong> Developed casino game signage in Unity3D, providing support and consulting for Bally’s senior engineering staff. Built to interact with table hardware such as roulette wheel to display table results with exciting graphics and video.</p>
								<p><strong>Platform:</strong> Custom Hardware</p>

								<ModalVideo channel='youtube' isOpen={this.state.isOpen[21]}  videoId='mwoyAzfm7eI' onClose={() => this.setState({isOpen: false})} />
								<button onClick= {this.openModal.bind(this, 21)} className="button-brush-1 link-button">watch trailer</button>
								
								<a className="link-button" href="https://www.sggaming.com/Games/Shuffle-Master/Electronic-Tables/Fusion/Fusion-Auto-Roulette-6627">
									<button className="button-brush-1">visit site</button>
								</a>
							</div>
							</Col>

							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={roulette} alt="game 2" />
									<img src={roulette2} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
							<div className="description">
								<h2>Roulette Signage</h2>
								<p><strong>Studio:</strong> AGS</p>
								<p><strong>Summary:</strong> Double-sided 29 roulette signage display with rich features. Couples playful roulette ball animations with detailed roulette trends. Alternate with marketing video content and idle game attract mode. The display has fully customizable layout, theme, and logo, as well as adjustable min/max limits.</p>
								<p><strong>Our Focus:</strong> Developed casino game signage in Unity. Overlayed statistical game data over dynamic 3D roulette table and synced character animations to winning numbers. Made game type, prizes, and appearance configurable via server interface. Designed display to be fully modular with customizable zones and automatic sizing.</p>
								<p><strong>Platform:</strong> Custom Hardware</p>

								<ModalVideo channel='youtube' isOpen={this.state.isOpen[22]}  videoId='hwQEWbW-Nxw' onClose={() => this.setState({isOpen: false})} />
								<button onClick= {this.openModal.bind(this, 22)} className="button-brush-1 link-button">watch trailer</button>
								
								<a href="http://playags.com/portfolio/roulette-signage/" className="link-button">
									<button className="button-brush-1">visit site</button>
								</a>
							</div>
							</Col>
							<Col xs="12" md="5">
							<div className="description">
								<img src={projectX} alt="game 1" />
							</div>
							</Col>
							<Col xs="12" md="5">
								<div className="p-5">
									<h2>PROJECT X</h2>
									<p>Currently under development, Namazu's team is providing Data Engineering and Scalable Architecture for a company in the automotive accessory industry. The team has been responsible for developing and delivering the following:</p>
									<ul>
										<li>Data engineering for ingestion, processing, and analytics of raw location-based IoT data.</li>
										<li>Real-time analytics of roadway traffic using data gathered from the company's proprietary IoT-based sensor network.</li>
										<li>A serverless and fully scalable RESTful API for communication with both devices and mobile applications.</li>
										<li>Supporting outside vendors in the development of client applications.</li>
										<li>Deployments, upgrades, and live DevOps Support</li>
									</ul>
								</div>
							</Col>
							<Col xs="12" md="5">
								<div className="p-5">
									<img src={projectY} alt="game 2" />
								</div>
							</Col>
							<Col xs="12" md="5">
							<div className="description">
								<h2>PROJECT Y</h2>
									<p>Currently under development. Namazu's team is responsible for developing the firmware communication architecture and SDK for a gaming-focused IoT device. The team is responsible for the development and SDK for the product. The team has developed the following so far:</p>
									<ul>
										<li>AI and Machine Learning algorithms for image and pattern recognition.</li>
										<li>Cross-platform native libraries built in C/C++ supporting Windows, OSX, Linux, Android, and iOS with a common API</li>
										<li>A proprietary communication protocol allowing game developers to interface their titles with devices in the field.</li>
										<li>Unity3D plugins to provide wrappers around the native C/C++ Code.</li>
										<li>DRM and anti-counterfeiting measures for the IoT devices.</li>
									</ul>
							</div>
							</Col>

							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={indycar} alt="game 2" />
									<img src={indycar2} alt="game 2" />
									<img src={indycar3} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
							<div className="description">
								<h2>INDYCAR</h2>
								<p><strong>Studio:</strong> Quickplay / Verizon</p>
								<p><strong>Summary:</strong> Race fans are in the driver’s seat with INDYCAR Mobile, as they take control by selecting their favorite driver or team. Featuring live streaming in-car camera feeds and radio broadcast. The INDYCAR Mobile App has evolved to become essential to INDYCAR fans.</p>
								<p><strong>Our Focus:</strong> Front-end engineering for iOS version. Improved UI design over existing Android version. Integrated with RESTful and live video stream servers. Social media services integration</p>
								<p><strong>Platform:</strong> iOS</p>
								<div className="row">
									<div className="col-sm-12">
										<ModalVideo channel='youtube' isOpen={this.state.isOpen[23]}  videoId='O_e6hookFCg' onClose={() => this.setState({isOpen: false})} />
										<button onClick= {this.openModal.bind(this, 23)} className="button-brush-1 link-button">watch trailer</button>
										
										<a className="link-button" href="https://itunes.apple.com/us/app/indycar-14/id606905722?mt=8">
											<img src={apple} />
										</a>
									</div>
								</div>
							</div>
							</Col>

							<Col xs="12" md="5" className="p-5">
								<Slider {...listOfGames.settings}>
									<img src={twerkApp} alt="game 2" />
									<img src={twerkApp2} alt="game 2" />
									<img src={twerkApp3} alt="game 2" />
									<img src={twerkApp4} alt="game 2" />
								</Slider>
							</Col>
							<Col xs="12" md="5">
							<div className="description">
								<h2>Twerk App by Big Freedia</h2>
								<p><strong>Studio:</strong> Mystic Coders / BitCandy Music Filter</p>
								<p><strong>Summary:</strong> Fans of Big Freedia make outrageous twerk videos with the free Big Freedia Twerk App. Players record themselves using the app, along with Big Freedia’s critically acclaimed music, and the app will automatically composite Big Freedia and her dancers into the video.</p>
								<p><strong>Our Focus:</strong> Native Android app that composites user recorded video with music and greenscreen video, placing the user in a scene with Big Freedia and dancers. Support for sharing videos to YouTube and Facebook.</p>
								<p><strong>Platform:</strong> Android</p>
								<div className="row">
									<div className="col-sm-12">
										<ModalVideo channel='youtube' isOpen={this.state.isOpen[24]}  videoId='a4cCPeK1Rdk' onClose={() => this.setState({isOpen: false})} />
										<button onClick= {this.openModal.bind(this, 24)} className="button-brush-1 link-button">watch trailer</button>
									</div>
								</div>
							</div>
							</Col>
						</Row>
					}
					</Row>
					<LetsMake />
				</Container>
			</>
		)
	}
}

export default PortfolioComponent