import React, { Component } from 'react'
import {
	Row, Col, Container
} from 'reactstrap'

import { isMobile } from 'react-device-detect'

import ReactGA from 'react-ga'
import './home.scss'
import * as ROUTES from '../../constants/routes'
import Slider from "react-slick"
import elements_logo from './../../assets/images/home/elements_logo.png'

import icon1 from './../../assets/images/home/icon-gamedesign_development.png'
import icon2 from './../../assets/images/home/icon-backendexpertise.png'
import icon3 from './../../assets/images/home/icon-brandedip.png'
import icon4 from './../../assets/images/home/icon-cloudservices.png'
import icon5 from './../../assets/images/home/icon-uiux.png'
import icon6 from './../../assets/images/home/icon-appdesign_development.png'

import client1 from './../../assets/images/home/our-clients/bush league.png'
import client2 from './../../assets/images/home/our-clients/konami.png'
import client3 from './../../assets/images/home/our-clients/AGS.png'
import client4 from './../../assets/images/home/our-clients/verison-logo.png'
import client5 from './../../assets/images/home/our-clients/ikin.png'
import client6 from './../../assets/images/home/our-clients/Bally-SHFL.png'
import client7 from './../../assets/images/home/our-clients/mystic.png'
import client8 from './../../assets/images/home/our-clients/Scientific Games.png'
import client9 from './../../assets/images/home/our-clients/kidlandia.png'

import featured1 from './../../assets/images/portfolio/01-Drop Squad.png'
import featured2 from './../../assets/images/portfolio/02-Stax.png'
import featured3 from './../../assets/images/portfolio/03-Nebulous.png'
import featured4 from './../../assets/images/portfolio/04-DDR.png'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import WhatTheySay from '../WhatTheySayComponent'

const INITIAL_STATE = {
	whatWeDo: [
		{
			url: "/services",
			img: {
				url: icon1,
				placeholder: "placeholder",
			},
			topic: "GAME DESIGN & DEVELOPMENT",
			paragraph: "We bring our concept to life by creating engaging content & gameplay"
		},
		{
			url: "/services",
			img: {
				url: icon2,
				placeholder: "placeholder",
			},
			topic: "APP DESIGN & DEVELOPMENT",
			paragraph: "We partner with your team to define an experience users won't forget"
		},
		{
			url: "/services",
			img: {
				url: icon3,
				placeholder: "placeholder",
			},
			topic: "BACKEND EXPERTISE",
			paragraph: "We built the only truly secure, massively scalable, & fully stacked backend technology available"
		},
		{
			url: "/services",
			img: {
				url: icon4,
				placeholder: "placeholder",
			},
			topic: "UI/UX",
			paragraph: "We design each project to achieve your business goals & provide an intuitive play experience"
		},
		{
			url: "/services",
			img: {
				url: icon5,
				placeholder: "placeholder",
			},
			topic: "CLOUD SERVICES",
			paragraph: "For digitally distributing content through scalable web services, we guarantee uptime to keep pace with user demands"
		},
		{
			url: "/services",
			img: {
				url: icon6,
				placeholder: "placeholder",
			},
			topic: "BRANDED IP",
			paragraph: "We are proud to have created applications for popular brands"
		}
	],
	successfulProducts: {
		settings: {
			dots: true,
			fade: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					dots: true,
					fade: true,
					infinite: true,
					speed: 500,
					slidesToShow: 1,
					slidesToScroll: 1,
				  }
				},
			  ]
		}
	},
	whatTheySay: {
		settings: {
			dots: true,
			fade: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1
		}
	}
}

class HomeComponent extends Component {
	constructor(props) {
		super(props)

		this.state = { ...INITIAL_STATE }
	}

	componentDidMount = () => {
		ReactGA.initialize('UA-172432218-1')
		ReactGA.event({
			category: 'page',
			action: 'User has entered at home page'
		})
	}

	render() {
		const { whatWeDo, whatTheySay, successfulProducts } = this.state
		return (
			<>
				<Container fluid id="home-component">
					<div className="bg-container full-width">
						<div className="successful-products">
							{/* {!isMobile && */}
							<div className="bg-white-part-one"></div>
							{/* } */}
							<Col xs="12" className="text-center n-p">
								<Slider {...successfulProducts.settings}>
									{/* <div className="product banner1"> */}
										<a href={ROUTES.PORTFOLIO}>
											<div className="product banner1"></div>
										</a>		
									{/* </div> */}

										<a href={ROUTES.PORTFOLIO}>
											<div className="product banner2"></div>
										</a>
									
									
										<a href={ROUTES.PORTFOLIO}>
											<div className="product banner3"></div>		
										</a>
								</Slider>
							</Col>
							{/* {!isMobile && */}
							<div className="bg-white-part-two"></div>
							{/* } */}
						</div>
					</div>

					<Row className="who-is-namazu">
						<Col xs="12" className="text-center">
							<h2>WHO IS NAMAZU?</h2>
							<p>We are an <strong>external development company</strong>, specialized in interactive media,
							 entertainment, and gaming. Since 2008 we've been honing the experience of developing original
							 software as well as porting existing software to additional platforms. We can offer a complete
							 solution to build your concept from design to final application. You've got a great idea - let us help you bring it to fruition.</p>
						</Col>
					</Row>
					<Row className="what-we-do full-width">
						<div className="bg-blue-part-one"></div>
						<Col xs="12" className="text-center namazu-b">
							<h2>WHAT WE DO</h2>
							<div className='space flex-row justify-content-between p-4 mspace'>
								{
									whatWeDo.length > 0 && whatWeDo.map((wwd, i) => {
										if (i < 3) {
											return (
												<div key={i} className="d-flex flex-column">
													<a href={wwd.url}>
														<div className={`${isMobile ? 'icon' : 'icon align-left'}`}>
															<img src={wwd.img.url} alt={wwd.img.placeholder} className="rounded" />
														</div>
														<div className="topic-name">
															<p className={`${isMobile ? '' : 'align-left'}`}>{wwd.topic}</p>
															<p className={`${isMobile ? '' : 'align-left'}`}>{wwd.paragraph}</p>
														</div>
													</a>
												</div>
											)
										}
									})
								}
							</div>
							<div className='space flex-row justify-content-between p-4 mspace'>
								{
									whatWeDo.length > 0 && whatWeDo.map((wwd, i) => {
										if (i > 2 && i < 6) {
											return (
												<div key={i} className="d-flex flex-column">
													<a href={wwd.url}>
														<div className={`${isMobile ? 'icon' : 'icon align-left'}`}>
															<img src={wwd.img.url} alt={wwd.img.placeholder} className="rounded" />
														</div>
														<div className="topic-name">
															<p className={`${isMobile ? '' : 'align-left'}`}>{wwd.topic}</p>
															<p className={`${isMobile ? '' : 'align-left'}`}>{wwd.paragraph}</p>
														</div>
													</a>
												</div>
											)
										}
									})
								}
							</div>
							<div>
								<a href={ROUTES.SERVICES}>
									<button className="button-brush-blue-1">know more</button>
								</a>
							</div>
						</Col>
						<div className="bg-blue-part-two"></div>
					</Row>
					<Row className="our-technology">
						<Col xs="12" className="text-center">
							<h2>OUR TECHNOLOGY</h2>
							<p><strong>Elements</strong> is a ready-to-plug-in and go backend for multiplayer games and applications,
							 without tying you down to a specific architecture or additional software purchases.
							</p>
							<div className="mt-3 mb-3">
								<img src={elements_logo} width="250px" alt="elements" className="img-fluid rounded" />
							</div>
							<div className="learn-more">
								<a href="https://www.namazuelements.com/">
									
									<button className="button-brush-dark-blue-2">learn more</button>
									
								</a>
							</div>
						</Col>
					</Row>
					<Row className="our-awesome-projects full-width">
						<div className="bg-grey-part-one"></div>
						<Col xs="12" className="text-center">
							<h2>OUR AWESOME PROJECTS</h2>
						</Col>

						<Row className="namazu-g">
						<Col xs="6" md="3">
							<div className="p-2 text-center">
								<a href={ROUTES.PORTFOLIO}>
									<img src={featured1} className="img-fluid mx-auto" alt="game" />
								</a>
							</div>
						</Col>
						<Col xs="6" md="3">
							<div className="p-2 text-center">
								<a href={ROUTES.PORTFOLIO}>
									<img src={featured2} className="img-fluid mx-auto" alt="game" />
								</a>
							</div>
						</Col>
						<Col xs="6" md="3">
							<div className="p-2 text-center">
								<a href={ROUTES.PORTFOLIO}>
									<img src={featured3} className="img-fluid mx-auto" alt="game" />
								</a>
							</div>
						</Col>
						<Col xs="6" md="3">
							<div className="p-2 text-center">
								<a href={ROUTES.PORTFOLIO}>
									<img src={featured4} className="img-fluid mx-auto" alt="game" />
								</a>
							</div>
						</Col>
						<Col xs="12" className="text-center bg-grey">
							<div className="mt-5">
								<a href={ROUTES.PORTFOLIO}>
									<button className="button-brush-blue-1">check out more projects</button>
								</a>
							</div>
						</Col>
						</Row>
						<div className="bg-grey-part-two"></div>
					</Row>
					{/* <Row className="our-awesome-projects">
						<Col xs="12" className="text-center">
							<h2>OUR AWESOME PROJECTS</h2>
							<div class="grid-container">
								<div class="image-1 bg-dark m-1 rounded">
									<a href="/">
										Project 1
									</a>
								</div>
								<div class="image-2 bg-dark m-1 rounded">
									<a href="/">
										Project 2
									</a>
								</div>
								<div class="image-3 bg-dark m-1 rounded">
									<a href="/">
										Project 3
									</a>
								</div>
								<div class="image-4 bg-dark m-1 rounded">
									<a href="/">
										Project 4
									</a>
								</div>
								<div class="image-5 bg-dark m-1 rounded">
									<a href="/">
										Project 5
									</a>
								</div>
								<div class="image-6 bg-dark m-1 rounded">
									<a href="/">
										Project 6
									</a>
								</div>
							</div>
							<div className="mt-5">
								<a href={ROUTES.PORTFOLIO}>
									<button className="button-brush-3">CTA to Portfolio page</button>
								</a>
							</div>
						</Col>
					</Row> */}
					{/* our awesome projects */}
					{/* our clients */}
					<Row className="our-clients">
						<Col xs="12" className="text-center">
							<h2>OUR CLIENTS</h2>
							<div className="client-row p-4">
								<div className="client-column">
									<a href="/">
										<div className="client-icon">
											<img src={client1} alt="Client Icon" />
										</div>
									</a>
								</div>
								<div className="client-column">
									<a href="/">
										<div className="client-icon">
											<img src={client2} alt="Client Icon" />
										</div>
									</a>
								</div>
								<div className="client-column">
									<a href="/">
										<div className="client-icon">
											<img src={client3} alt="Client Icon" />
										</div>
									</a>
								</div>
								<div className="client-column">
									<a href="/">
										<div className="client-icon">
											<img src={client4} alt="Client Icon" />
										</div>
									</a>
								</div>
								<div className="client-column">
									<a href="/">
										<div className="client-icon">
											<img src={client5} alt="Client Icon" />
										</div>
									</a>
								</div>
							</div>
							<div className="client-row p-4">
								<div className="client-column">
									<a href="/">
										<div className="client-icon">
											<img src={client6} alt="Client Icon" />
										</div>
									</a>
								</div>
								<div className="client-column">
									<a href="/">
										<div className="client-icon">
											<img src={client7} alt="Client Icon" />
										</div>
									</a>
								</div>
								<div className="client-column">
									<a href="/">
										<div className="client-icon">
											<img src={client8} alt="Client Icon" />
										</div>
									</a>
								</div>
								<div className="client-column">
									<a href="/">
										<div className="client-icon">
											<img src={client9} alt="Client Icon" />
										</div>
									</a>
								</div>
							</div>
						</Col>
					</Row>
					<WhatTheySay whatTheySay={whatTheySay} />
					<Row className="lets-talk full-width">
						<div className="bg-blue-mirrored-part-one"></div>
						<Col xs="12" className="text-center align-vertically">
							<h2>LET'S GET STARTED</h2>
							<p>Discover how our talented team can boost your project!</p>
							<div className="mt-5">
								<a href={ROUTES.CONTACT}>
									<button className="button-brush-blue-1">get in touch</button>
								</a>
							</div>
						</Col>
						<div className="bg-blue-mirrored-part-two"></div>
					</Row>
				</Container>
			</>
		)
	}
}

export default HomeComponent