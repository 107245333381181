import React from 'react'
import * as ROUTES from '../../constants/routes'

import './whattheysay.scss'
import { Row, Col } from 'reactstrap'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const WhatTheySay = (props) => {
	console.log(props)
	return (
		<div className="what-they-say">
			<Col xs="12" className="text-center">
				<h2>WHAT THEY SAY</h2>
				<div className="mt-5">
					<Slider {...props.whatTheySay.settings}>
						<div>
							<p className="quote">"A core component of our game Drop Squad™: The Escape are the PvP and tournament modes. With Elements, the Namazu Studios team gave us a seamless solution that conjoins the spirit of both gaming worlds!"</p>
							<p className="name">TJ Fechser,  Bush League Games</p>
						</div>
						<div>
							<p className="quote">We contracted with Namazu Studios for their expertise in iPhone development, game design, and user interfaces. Their team had an extremely efficient workflow with flexibility to accommodate our team’s way of working. I highly recommend them and would relish the chance to work with them in the future."</p>
							<p className="name">Dan Hewitt, Konami</p>
						</div>
						<div>
							<p className="quote">"IKIN has benefited greatly from using Namazu Studios for multiple projects; Building out machine learning frameworks to create novel user experiences. Namazu has successfully built both cross-platform and embeddable back end A.I. frameworks that will be the foundation of many new experiences for users in the years to come.</p>
							<p className="name">Taylor Scott, CTO – IKIN</p>
						</div>
					</Slider>
				</div>
			</Col>
		</div>
	)
}

export default WhatTheySay
